<template>
  <div class="d-flex flex-column text-center align-items-center main-container pt-5" v-if="!isLoading">
    <div class="main-header col-12">
      <div class="col-12">STOP THE CLOCK</div>
      <img class="col-9 mb-5" src="../../assets/horizontal-line.png">
    </div>
    <div class="how-to-play-container">
      <img class="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11" src="../../assets/how-to-play-steps.png">
    </div>
    <div v-if="brandConfig.productId === 4" class="col-10">Today's competition has now come to an end. Watch this space for competitions in the near future!</div>
    <button v-if="dlsUserPlayedToday === undefined && brandConfig.productId !== 4" @click="playButtonClicked()" class="play-button col-10">LET'S PLAY</button>
    <button @click="leaderboardButtonClicked()" class="leaderboard-button mt-3 col-7">LEADERBOARD</button>
    <div @click="termsConditionsClicked()" class="mt-2 terms-conditions-text">Terms and Conditions</div>
    <div class="mt-3">
      <img class="col-5" src="../../assets/stc-quizinc-logo.png">
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";

export default {
  name: 'GamePlayLanding',
  components: {},
  computed: {
    ...mapState(['isLoading', 'user', 'brandConfig', 'dlsUserPlayedToday'])
  },
  data() {
    return {}
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setBurgerMenuIconVisible', 'setUser', 'setDlsUserPlayedToday']),
    playButtonClicked() {
      this.$router.push('/question-screen')
    },
    leaderboardButtonClicked() {
      this.$router.push('/leaderboard')
    },
    termsConditionsClicked() {
      window.location = 'https://quizinc.co.za/terms_and_conditions';
    },
    checkUserState() {
      if (this.user.status === 'Cancelled') {
        this.setBurgerMenuIconVisible(false);
        this.$router.push('/login')
        this.setUser(undefined);
      }
    },
  },
  async beforeMount() {
    this.setIsLoading(true)
    this.setBurgerMenuIconVisible(true);
    this.checkUserState();
    this.setIsLoading(false)
  }
}
</script>
<style scoped>
@font-face {
  font-family: "Komikax";
  src: local("Komikax"),
  url(../../assets/fonts/KOMIKAX_.ttf) format("truetype");
}

.main-container {
  font-family: "Komikax", sans-serif;
  color: #FFFFFF;
}

.main-header {
  font-size: 30px;
}

.how-to-play-container {
  margin-top: -40px;
}

.play-button {
  color: #FFFFFF;
  background-color: #3cc23c;
  border: 0;
  border-radius: 16px;
  font-size: 18px;
  height: 50px;
}

.leaderboard-button {
  color: #FFFFFF;
  background-color: #ff0303;
  font-size: 16px;
  border: 0;
  border-radius: 12px;
  height: 40px;
}

.terms-conditions-text {
  font-size: 10px;
  text-decoration: underline;
}

@media screen and (max-width: 400px) {
  .main-header {
    font-size: 26px;
    margin-top: -20px;
  }

  .how-to-play-container {
    margin-top: -50px;
  }

  .leaderboard-button {
    font-size: 14px;
    height: 32px;
  }
}

</style>
