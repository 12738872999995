<template>
  <div class="d-flex flex-column text-center align-items-center main-container pt-5"
       v-if="!isLoading && arrayTriviaQuestions">
    <div class="d-flex flex-column align-items-center question-header col-12">
      <div class="col-10">{{ arrayTriviaQuestions[0].question }}</div>
      <img class="col-10  mt-1" src="../../assets/horizontal-line.png">
    </div>
    <div class="mt-3">
      <div class="select-answer-text col-6 mb-1">Please select an answer:</div>
      <div class="point-answer mb-3"
           @click="answerSelected(0, arrayTriviaQuestions[0].answers[0], arrayTriviaQuestions[0])">
        <img class="col-9 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../../assets/answer-block-icon.png">
        <div class="alphabet-text">A</div>
        <div
            :class="`col-8 col-sm-7 col-md-8 col-lg-9 col-xl-10 ${fontDefaultSize === true ? 'centered-answer-default' : 'centered-answer-small'}`">
          {{ arrayTriviaQuestions[0].answers[0] }}
        </div>
      </div>
      <div class="point-answer mb-3"
           @click="answerSelected(1, arrayTriviaQuestions[0].answers[1], arrayTriviaQuestions[0])">
        <img class="col-9 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../../assets/answer-block-icon.png">
        <div class="alphabet-text">B</div>
        <div
            :class="`col-8 col-sm-7 col-md-8 col-lg-9 col-xl-10 ${fontDefaultSize === true ? 'centered-answer-default' : 'centered-answer-small'}`">
          {{ arrayTriviaQuestions[0].answers[1] }}
        </div>
      </div>
      <div class="point-answer mb-3"
           @click="answerSelected(1, arrayTriviaQuestions[0].answers[2], arrayTriviaQuestions[0])">
        <img class="col-9 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../../assets/answer-block-icon.png">
        <div class="alphabet-text">C</div>
        <div
            :class="`col-8 col-sm-7 col-md-8 col-lg-9 col-xl-10 ${fontDefaultSize === true ? 'centered-answer-default' : 'centered-answer-small'}`">
          {{ arrayTriviaQuestions[0].answers[2] }}
        </div>
      </div>
      <div class="point-answer mb-3"
           @click="answerSelected(1, arrayTriviaQuestions[0].answers[3], arrayTriviaQuestions[0])">
        <img class="col-9 col-sm-8 col-md-9 col-lg-10 col-xl-11" src="../../assets/answer-block-icon.png">
        <div class="alphabet-text">D</div>
        <div
            :class="`col-8 col-sm-7 col-md-8 col-lg-9 col-xl-10 ${fontDefaultSize === true ? 'centered-answer-default' : 'centered-answer-small'}`">
          {{ arrayTriviaQuestions[0].answers[3] }}
        </div>
      </div>
    </div>
    <div class="question-number">
      Question {{ questionNumber }}/10
    </div>
    <ClockTimer/>
    <div class="mt-1">
      <img class="col-5" src="../../assets/stc-quizinc-logo.png">
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import ClockTimer from "@/views/components/ClockTimer";
import TriviaDataService from "@/services/triviaDataService";
import {dlsStrategyQuestionCategoryId} from "@/functions/dlsStrategyQuestionCategoryId";

export default {
  name: "QuestionScreen",
  components: {ClockTimer},
  computed: {
    ...mapState(['isLoading', 'user', 'brandConfig', 'token', 'encryptedMsisdn'])
  },
  data() {
    return {
      arrayTriviaQuestions: undefined,
      questionNumber: 1,
      answerIndex: undefined,
      userAnswer: undefined,
      questionId: undefined,
      questionIndex: 0,
      totalCorrectAnswers: 0,
      answerObject: undefined,
      gameId: undefined,
      fontDefaultSize: true,
    }
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUserScore', 'setUserQuizFinishingTime', 'setDlsUserPlayedToday']),
    async answerSelected(index, answer, questionArray) {
      this.setIsLoading(true);
      this.answerIndex = index
      this.userAnswer = answer;
      this.questionCategoryId = questionArray.questionCategoryId;
      this.questionId = questionArray.questionId;
      this.createAnswerObject();
      await this.submitUserAnswer();
      await this.continueQuizCheck();
      this.setIsLoading(false);
    },
    createAnswerObject() {
      this.answerObject = {
        encryptedMsisdn: this.token,
        questionCategoryId: this.questionCategoryId,
        answer: this.userAnswer,
        questionId: this.questionId
      };
    },
    async submitUserAnswer() {
      this.submitUserAnswerResponse = await TriviaDataService.submitUserAnswerV2([this.answerObject], [this.gameId]);
      this.questionNumber++;
    },
    async newSetQuestionsAnswers() {
      this.setIsLoading(true);
      let arrayTriviaQuestionsResponse = await TriviaDataService.getTriviaQuestionsV2(this.token, dlsStrategyQuestionCategoryId(this.brandConfig.categoryId), new Date().toISOString())
      this.arrayTriviaQuestions = arrayTriviaQuestionsResponse.data;
      this.gameId = this.arrayTriviaQuestions[0].gameId;
      await this.checkAnswersCharacterLength()
      this.setIsLoading(false);
    },
    async continueQuizCheck() {
      let submissionResponseArray = this.submitUserAnswerResponse.data.recordset
      if (submissionResponseArray[0].TotalQuestions === 10) {
        this.endQuizAndRedirect();
      } else await this.newSetQuestionsAnswers();
    },
    calculateScore() {
      let answersArray = this.submitUserAnswerResponse.data.recordset
      this.userQuizFinishingTime = answersArray[0].GameDurationSeconds;
      this.totalCorrectAnswers = answersArray[0].CorrectAnswer;
      this.setUserScore(this.totalCorrectAnswers);
      this.setUserQuizFinishingTime(this.userQuizFinishingTime);
    },
    endQuizAndRedirect() {
      this.calculateScore();
      if (this.totalCorrectAnswers > 6) this.$router.push('/game-complete')
      else this.$router.push('/game-fail')
    },
    checkCurrentQuestionNumber() {
      if (this.arrayTriviaQuestions[0].questionsAnsweredCount.length !== 0 && this.brandConfig.productId === 3) {
        this.questionNumber = this.arrayTriviaQuestions[0].questionsAnsweredCount[0].QuestionsAnsweredCount + 1;
      }
    },
    async checkAnswersCharacterLength() {
      let answersArray = this.arrayTriviaQuestions[0].answers
      for (let i = 0; i < answersArray.length; i++) {
        if (answersArray[i].length >= 26) {
          this.fontDefaultSize = false
          break
        } else this.fontDefaultSize = true
      }
    },
    async loadNextQuestionSet() {
      this.arrayTriviaQuestions = this.arrayTriviaQuestionsResponse.data;
      this.gameId = this.arrayTriviaQuestions[0].gameId;
      this.checkCurrentQuestionNumber();
      await this.checkAnswersCharacterLength();
    },
    async checkDlsStrategyUserAnsweredQuestionCount() {
      if (this.brandConfig.productId === 4) {
        let getTriviaQuestionsResponse = await TriviaDataService.getTriviaQuestionsV2(this.token, dlsStrategyQuestionCategoryId(this.brandConfig.categoryId), new Date().toISOString());
        if (getTriviaQuestionsResponse.data.length === 0) {
          this.setDlsUserPlayedToday(true)
          this.$router.push('/landing')
        } else {
          await this.loadNextQuestionSet()
        }
      } else {
        await this.loadNextQuestionSet()
      }
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    this.arrayTriviaQuestionsResponse = await TriviaDataService.getTriviaQuestionsV2(this.token, dlsStrategyQuestionCategoryId(this.brandConfig.categoryId), new Date().toISOString());
    await this.checkDlsStrategyUserAnsweredQuestionCount()
    this.setIsLoading(false);
  }
}
</script>

<style scoped>
.main-container {
  font-family: 'Roboto', sans-serif;
  color: #FFFFFF;
}

.question-header {
  font-size: 18px;
}

.select-answer-text {
  position: relative;
  left: 24px;
  font-size: 12px;
}

.point-answer {
  position: relative;
  text-align: center;
  color: #FFFFFF;
}

.alphabet-text {
  position: absolute;
  top: 15%;
  left: 17%;
  font-family: "Komikax", sans-serif;
  font-size: 24px;
}

.centered-answer-default, .centered-answer-small {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-answer-default {
  font-size: 18px;
}

.centered-answer-small {
  font-size: 13px;
}

@media screen and (max-width: 400px) {
  .alphabet-text {
    font-size: 18px;
  }

  .centered-answer-default {
    font-size: 14px;
  }

  .centered-answer-small {
    font-size: 12.5px;
  }

  .question-header {
    font-size: 16px;
    margin-top: -15px;
  }

  .question-number {
    margin-top: -5px;
  }
}

</style>
