<template>
  <div class="d-flex flex-column text-center align-items-center main-container">
    <img class="col-5" src="../../../assets/restricted-icon.png">
    <div class="col-9">Your request to Vodacom Quizinc Stop The Clock has been rejected due to Content Block set on your Vodacom number. Please contact your Vodacom administrator.</div>
    <div class="d-flex flex-column align-items-center footer col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2">
      <img class="col-5 mb-3" src="../../../assets/stc-quizinc-logo.png">
      <div @click="termsConditionsClicked()"> T&C's Apply | Subscription Service | R3/day | First Day Free</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentBlock",
  methods: {
    termsConditionsClicked() {
      window.location = 'https://quizinc.co.za/terms_and_conditions';
    },
  },
}
</script>

<style scoped>
.main-container {
  color: #FFFFFF;
  padding-top: 40px;
  font-family: "Arial", sans-serif;
}

.footer {
  display: flex;
  text-align: center;
  position: fixed;
  bottom: 5px;
  font-size: 12px;
  color: #808080FF;
}
</style>
