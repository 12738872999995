<template>
  <div class="d-flex flex-column align-items-center main-container">
    <div class="header-text mt-5 mb-1 text-center">Stop The Clock Trivia Terms and Conditions</div>
    <div class="secondary-text mt-3 col-10">
      <div>
        1. These terms and conditions govern the relationship between You (“You” or “Your”) and
        Vodacom Proprietary Limited and its affiliates (hereinafter “Vodacom” or “Us” or “We”)
        and Fanclash South Africa Pty Ltd (“Partner”) regarding Your use of the Stop The Clock
        Trivia service and related features (the “Service”) (“Terms and Conditions”). Use of
        the Service is also governed by the Privacy Policy which is incorporated herein by
        reference.
      </div>
      <div class="mt-3">
        2. These terms and conditions for the Service shall be read together with the Partner’s
        terms and conditions available at: <a style="text-decoration: underline">https://quizinc.co.za/terms_and_conditions</a>.
      </div>
      <div class="mt-3">
        <a style="text-decoration: underline">IMPORTANT PROVISION: ACCEPTANCE OF THESE TERMS AND CONDITIONS</a>.
      </div>
      <div class="mt-3">
        3. <a style="text-decoration: underline">PLEASE READ THESE TERMS AND CONDITIONS AND THE  PRIVACY POLICY
        CAREFULLY BEFORE USING THE SERVICE OR SUBMITTING INFORMATION
        THROUGH THE SERVICE. BY USING THE SERVICE OR SUBMITTING
        INFORMATION THROUGH THE SERVICE, YOU ARE AGREEING TO THESE
        TERMS AND CONDITIONS. IF YOU DO NOT AGREE, PLEASE DO NOT USE THIS
        SERVICE OR SUBMIT ANY INFORMATION THROUGH THE SERVICE. WE
        RESERVE THE RIGHT, AT ANY TIME, TO MODIFY AND UPDATE THESE TERMS
        AND CONDITIONS FROM TIME TO TIME BY POSTING SUCH UPDATED TERMS
        AND CONDITIONS ON THE VODACOM WEBSITE. ANY SUCH UPDATES SHALL
        APPLY TO YOU AND YOUR USE OF THE SERVICE AND BY CONTINUING TO USE
        THE SERVICE, YOU SHALL BE DEEMED TO HAVE ACCEPTED SUCH CHANGES.</a>.
      </div>
      <div class="mt-3">
        <a style="text-decoration: underline">HOW TO SUBSCRIBE OR UNSUBSCRIBE TO THE SERVICE?</a>.
      </div>
      <div class="mt-2">
        3.1. In order to subscribe the Service, You must:
      </div>
      <div class="mt-2">
        3.1.1 dial USSD: *117*76#, press option 1 to select their subscription, then press option 1
        to confirm the subscription or
      </div>
      <div class="mt-2">
        3.1.1 visit Web: <a style="text-decoration: underline">www.Quizinc.co.za/stoptheclock,</a>. click on the subscribe button and confirm
        your subscription.
      </div>
      <div class="mt-2">
        3.2 You are entitled to unsubscribe from the Service at any time and for any reason by
        following the “cancel” prompts available within the Service menu, or by:
      </div>
      <div class="mt-2">
        3.2.1. using Vodacom self-service channels;
      </div>
      <div class="mt-2">
        3.2.2 calling Vodacom Customer Care: 082 135; or
      </div>
      <div class="mt-2">
        3.2.3 dialling USSD: *135*997#
      </div>
      <div class="mt-2">
        3.3 When cancelling/ unsubscribing from the Service, You accept that Your old profile and
        preferences will be retained for a 6 (six) month period and for no longer than 5 years
        (only if necessary), in order to facilitate a convenient experience should You choose to
        re-subscribe at a later stage within the 6 (six) month period.
      </div>
      <div class="mt-3">
        <a style="text-decoration: underline">GENERAL:</a>
      </div>
      <div class="mt-2">
        3.4. As a user of the Service, You will have the option to subscribe on a recurring basis,
        making You a paying user and doing so accordingly binds You to these Terms and
        Conditions. As a subscribed user, You will be asked to submit personal information to
        us. We are committed to ensuring responsible practices in the collection, use and
        disclosure of Your personal information in accordance with applicable law and the
        Privacy Policy incorporated by reference into and form a binding part of these Terms
        and Conditions.
      </div>
      <div class="mt-2">
        3.5 By subscribing to the Service, You agree to the following additional rules upon
        successful initial registration for the Service:
      </div>
      <div class="mt-2">
        3.5.1 You will be eligible to access all the content on the Service free of charge for 1 (one)
        day (the “Free Trial Day”). For the avoidance of doubt, You will not be eligible for
        another Free Trial Day if You cancel Your subscription and then re-register at a later
        stage.
      </div>
      <div class="mt-2">
        3.5.2 After the Free Trial Day, You will be charged a subscription fee of ZAR 3 (three
        Rand) per day or ZAR 25 (twenty five Rand) per month (the “Fee”) for the relevant
        period that You remain subscribed for the Service.
      </div>
      <div class="mt-2">
        3.6 If You are a pre-paid or top-up user:
      </div>
      <div class="mt-2">
        3.6.1 the Fee will be deducted from Your available airtime balance depending on the
        subscription that you have chosen. You acknowledge that You must have enough
        airtime available to pay the Fee to continue to use the Service;
      </div>
      <div class="mt-2">
        3.6.2 if You fail to satisfy Your Fee obligation for 90 (ninety) consecutive days after the last
        successful Fee was collected, Vodacom may at its sole discretion suspend or
        terminate Your subscription to the Service;
      </div>
      <div class="mt-2">
        3.6.3 the Fee for each day of the month that You were subscribed to the Service for will be
        set out in Your monthly statement for payment. If You do not pay Your monthly Fee
        set out in Your monthly statement, Your subscription may be suspended;
      </div>
      <div class="mt-2">
        3.6.4 before the expiry of the Free Trial Day, You may cancel Your subscription to the
        Service to avoid being charged the Fee.
      </div>
      <div class="mt-2">
        3.6.5 only one subscription is permitted per mobile number. Vodacom and non-Vodacom
        (where they can do so) users within the Republic of South Africa may subscribe.
      </div>
      <div class="mt-2">
        3.7 If You are a contract user, the Fee will be set out in Your monthly statement. If You
        do not pay Your monthly Fee, Your subscription may be suspended by Vodacom in its
        discretion.
      </div>
      <div class="mt-3">
        <a style="text-decoration: underline">MICRO-BILLING:</a>
      </div>
      <div class="mt-2">
        3.8. If Vodacom are unable to bill the full value of the subscription You have chosen for the
        Service, we may implement micro-billing. Micro billing occurs when You have
        subscribed to Service and You do not have sufficient funds in Your account (i.e., Your
        airtime balance) to pay for the subscription that You have chosen. Micro-billing will
        allow Vodacom to charge a discounted subscription or any reduced amount as we
        may determine from time to time dependent on the available balance until we can
        charge You the full amount. If we are successful in billing an amount, in part of in full
        for the end of the applicable period (depending on the subscription chosen), that billing
        record will stand as the completed billing for that particular period. Billing for the next
        period will revert back to attempting to bill the full stated price. Billing does not occur in
        arrears. Micro-billing shall be implemented in Vodacom’s sole and absolute discretion.
        You must have enough airtime available for payment of the subscription You have
        chosen for the Service to keep Your subscription in an active state. You acknowledge
        that in the event we are unable to bill any valid value for Your subscription of the
        Service that You have chosen, we will continue to attempt to bill and enable the
        subscription for a maximum period of 90 (ninety) consecutive days after the last
        successful bill collected. Should we be unable to collect any funds for a period of 90
        (ninety) consecutive days, You will be automatically unsubscribed from the Service.
      </div>
      <div class="mt-2">
        3.9. You give us consent to provide You with various messages about / based on Your
        interaction with the Service. These messages will be delivered to You in the form of
        text messages sent to Your mobile device using the mobile number identifying You as
        a user of the Service.
      </div>
      <div class="mt-2">
        3.10. These messages include, but are not limited to:
      </div>
      <div class="mt-2">
        3.10.1 periodic messages to encourage, or remind You to browse or access the Service;
      </div>
      <div class="mt-2">
        3.10.2 messages inviting You to share, voluntarily, data about Yourself or Your preferences;
      </div>
      <div class="mt-2">
        3.10.3 messages inviting You to try alternate versions of the Service based on Vodacom’s
        knowledge of the device You are using to access the Service;
      </div>
      <div class="mt-2">
        3.10.4 confirmation messages relating to Your subscribing for, or cancellation of, the
        Service;
      </div>
      <div class="mt-2">
        3.10.5 messages inviting You to rate the Service’s quality and Your opinion of Your
        experience of the Service as measured by the metric scale provided;
      </div>
      <div class="mt-2">
        3.10.6 messages with Service content, including, but not limited to, confirmation of weekly
        results/ draws/ picks, and;
      </div>
      <div class="mt-2">
        3.10.7 messages relating to the earning, notification, and servicing of rewards.
      </div>
      <div class="mt-2">
        3.11. You may opt out of the messages above except for the system messages which are
        mandatory in order for us to correctly administrate Your use of the Service. To opt out
        of the messages, You are advised to make use of the functionality on the Service
        settings to cancel messages, or alternatively, to send an opt-out message request to
        SMS STOP To 30881 or dial *135*18.
      </div>
      <div class="mt-2">
        3.12. You will not be charged for interactions that You will have with the Service via SMS.
        Data charges may apply for accessing the Service on website.
      </div>
      <div class="mt-2">
        3.13. You accept that the Service’s default language is English.
      </div>
      <div class="mt-2">
        3.14. By subscribing, You agree that You will be opted into receiving the messages
        mentioned above, which You may cancel at any time via the Service menu.
      </div>
      <div class="mt-2">
        3.15. You acknowledge that Your subscription information will be retained for use to allow
        You access to future iterations and new features of the Service, and that You will be
        engaged to continue using the Service as applicable for other functionality intended to
        be covered by the Service.
      </div>
      <div class="mt-2">
        3.16. You acknowledge that any subscriptions in error will be billed. For Your protection, we
        ask You to confirm all purchases twice.
      </div>
      <div class="mt-3">
        <a style="text-decoration: underline">REWARDS &amp; PRIZES (WHERE APPLICABLE)</a>.
      </div>
      <div class="mt-2">
        3.17. If applicable and to be eligible for rewards from the Service, You acknowledge that
        You need to have an active subscription for the Service.
      </div>
      <div class="mt-2">
        3.18. You acknowledge, understand, and agree that for Your subscription to the Service
        does not guarantee any prizes or rewards from the Service. We shall not have any
        liability for such loss or damage while using the Service.
      </div>
      <div class="mt-2">
        3.19. Where applicable, prizes may not be substituted or transferred. All taxes associated
        with the receipt or use of any prize is the sole responsibility of the winner. If any legal
        authority contests the awarding of a prize, we may, in our sole discretion, determine
        whether or not to award such a prize.
      </div>
      <div class="mt-2">
        3.20. Rewards can only be earned if You are a Vodacom customer. Any non-Vodacom
        customers will be unable to earn rewards due to their being active on a different
        network.
      </div>
      <div class="mt-2">
        3.21. Only the qualifying Vodacom mobile number that is subscribed to the Service, can
        earn rewards.
      </div>
      <div class="mt-2">
        3.22. In the event that You terminate Your Vodacom contract, You accept that You forfeit
        the use of any unused rewards by that point as well as the earning of future rewards
        from that point.
      </div>
      <div class="mt-3">
        <a style="text-decoration: underline">GAME RULES OR SUBSCRIPTION RULES</a>.
      </div>
      <div class="mt-2">
        3.23. Users will be granted access to the Stop the Clock game area on Quizinc.co.za,
        stopclock.co.za and on USSD.

        The Promotional offer is as follows:
        Users will be allowed to answer up to 10 time-limited questions during a Stop the
        Clock gameplay session where each question has to be answered within 20 seconds.
        At the end of a gameplay session, the users’ cumulative time will be displayed on the
        daily leader board.
      </div>
      <div class="mt-2">
        3.24. Customers can subscribe to the Promotional offer above by dialling *117*76# or by
        visiting the Vodacom website.
      </div>
      <div class="mt-2">
        3.25. The subscription process (and terms and conditions) required for the Service shall at
        all times be applicable and shall constitute an agreement between You and the
        Partner. Vodacom shall not be liable for any unavailability, faults glitches, bugs, or
        technical issues with the Partner’s application and/or URL. Furthermore, Vodacom
        shall not be held liable for any damages or loss howsoever arising that You may suffer
        due to use of the Partner’s application and/or URL.
      </div>
      <div class="mt-2">
        3.26. No data pro-ration will occur.
      </div>
      <div class="mt-2">
        3.27. If You use the applications outside of the Service, You will be charged as per Your
        existing tariff plan. If You follow a link outside the Partner’s application and/or URL,
        normal data rates will apply as per Your existing tariff plan.
      </div>
      <div class="mt-2">
        3.28. When making use of the Service, certain applications and/or URL may contain third
        party advertisements, content, materials, and/or links to third party services and / or
        websites that are not owned or controlled by Vodacom. Vodacom assumes no
        responsibility for, the content, privacy policies or practices of any third-party websites
        or applications. In addition, Vodacom does not and cannot censor or edit the content
        of any third-party website. When You visit third party websites, Vodacom encourages
        You to read the terms and conditions and privacy policies of the other websites. By
        using the Service, You expressly release Vodacom from all liability arising from Your
        use of any third-party website or because of any third-party services.
      </div>
      <div class="mt-2">
        3.29. You cannot use the Services when You are roaming. Whilst roaming the use of an
        application and/or URL forming part of the Service, will be charged at Your existing
        tariff plan..
      </div>
      <div class="mt-2">
        3.30. If You perform a price plan migration, whilst using You have an existing active Service,
        such Service shall be carried over to the new price plan.
      </div>
      <div class="mt-2">
        3.31. If You perform a price plan upgrade, You will be able to add the Service to Your
        upgraded price plan.
      </div>
      <div class="mt-2">
        3.32. Vodacom reserves the right to terminate or change the Service at any time and for
        whatsoever reason provided the on-going subscription for the Service will not be
        affected
      </div>
      <div class="mt-3">
        <a style="text-decoration: underline">YOUR OBLIGATIONS AND ENTITLEMENTS</a>.
      </div>
      <div class="mt-2">
        3.33. Vodacom and/or its appointed Partner reserves the right to determine what conduct it
        considers to be in violation of the rules of use or otherwise outside the intent or spirit of
        these Terms and Conditions or the Service itself. We reserve the right to act as a
        result, which may include, if relevant, suspending the Service, terminating Your
        registration, and prohibiting You from using the Service. Said suspension, termination
        and prohibition may occur with or without notice to You, and as a result may involve
        the loss of any benefits, privileges or earned items associated with Your use of the
        Service, and we are under no obligation to compensate You for any such losses or
        results.
      </div>
      <div class="mt-3">
        <a style="text-decoration: underline">LIMITATION OF LIABILITY</a>.
      </div>
      <div class="mt-2">
        3.34. You agree that Your use of the Service shall be at Your sole risk. To the fullest extent
        permitted by law, we and our officers, directors, employees, and agents disclaim all
        warranties, express or implied, in connection with the services and Your use thereof.
        We make no warranties or representations about the accuracy or completeness of the
        Service’s content or the content of any websites or services linked to these services
        and assume no liability or responsibility for any (i) errors, mistakes, or inaccuracies of
        content; (ii) personal injury or property damage of any nature whatsoever resulting
        from Your access to and use of the Service; (iii) any unauthorized access to or use of
        our servers and/or any and all personal information and/or financial information stored
        therein; (iv) any interruption or cessation of transmission to or from the Service; (v) any
        bugs, viruses, trojan horses or the like which may be transmitted to or through the
        Service by any third party; or (vi) any errors or omissions in any content or for any loss
        or damage of any kind incurred as a result of the use of any content posted, emailed,
        transmitted, or otherwise made available via the Service. We do not warrant, endorse,
        guarantee, or assume responsibility for any product or Service advertised or offered by
        a third party through the Service or any hyperlinked website or featured in any banner
        or other advertising, and we will not be a party to or in any way be responsible for
        monitoring any transaction between You and third-party providers of products or
        services.
      </div>
      <div class="mt-2">
        3.35. To the extent permitted by applicable law, in no event shall we or our subsidiaries and
        affiliates, and their respective officers, directors, owners, agents, employees,
        representatives and licensors be liable for any special, incidental, indirect,
        consequential, punitive or exemplary losses or damages whatsoever or for loss of
        profits (including, without limitation, damages for loss of revenue, loss of data, failure
        to realize expected savings, interruption of activities, or any other pecuniary or
        economic loss) and whether arising from breach of contract, damages (including
        negligence), strict liability or otherwise, arising out of the use of or inability to use the
        Service or its content, or any product or service described or provided through the
        Service.
      </div>
      <div class="mt-2">
        3.36. Save to the extent permitted by law, You agree to indemnify, defend and hold
        harmless Vodacom from and against any and all claims, demands, actions, liability,
        losses, costs and expenses (including legal fees and expenses) arising from or related
        to: (i) any use or alleged use of the Service by any other person, whether or not
        authorized by You; (ii) Your breach of these Terms and Conditions, including any
        breach of Your representations and warranties herein; and (iii) Your violation of any
        law or (iv) Your use of or inability to use the Service, the contents, any hyperlinked
        website, and any of the products and services made available on the Service thereof.
      </div>
      <div class="mt-3 text-center">
        <a style="text-decoration: underline">TERMS AND CONDITIONS:</a>
      </div>
      <div class="mt-2 text-center">
        <a style="text-decoration: underline">STOP THE CLOCK LAUNCH COMPETITION</a>
      </div>
      <div class="mt-2">
        1. <a style="text-decoration: underline">Introduction:</a>
      </div>
      <div class="mt-2">
        1.1. Vodacom (Pty) Ltd (“<a style="font-weight:600">Vodacom</a>”) and Fanclash South Africa (Pty) Ltd “<a style="font-weight:600">Partner</a>” are
        running a Competition where customers stand a chance of winning their share of 1830
        GB (one thousand eight hundred and thirty gigabytes) of data bundles by subscribing
        to the Stop the Clock service and playing the Stop the Clock game (the
        <a style="font-weight:600">"Competition</a>").
      </div>
      <div class="mt-2">
        <a style="text-decoration: underline">IMPORTANT PROVISION: ACCEPTANCE OF THESE TERMS AND CONDITIONS</a>
      </div>
      <div class="mt-2 text-center">
        <a style="text-decoration: underline">PLEASE READ THESE TERMS AND CONDITIONS AND THE  PRIVACY POLICY
          CAREFULLY BEFORE PARTICIPATING IN THIS COMPETITION. BY ENTERING
          THE COMEPTITION, YOU ARE AGREEING TO BE BOUND BY THESE TERMS AND
          CONDITIONS. IF YOU DO NOT AGREE, PLEASE DO NOT ENTER THE
          COMPETITION OR SUBMIT ANY INFORMATION IN THIS REGARD. WE RESERVE
          THE RIGHT, AT ANY TIME, TO MODIFY AND UPDATE THESE TERMS AND
          CONDITIONS FROM TIME TO TIME BY POSTING SUCH UPDATED TERMS AND
          CONDITIONS ON THE VODACOM WEBSITE. ANY SUCH UPDATES SHALL APPLY
          TO YOU AND YOUR ENTRY INTO THE COMPETITION AND YOU SHALL BE
          DEEMED TO HAVE ACCEPTED SUCH CHANGES.</a>
      </div>
      <div class="mt-2 text-center">
        <a style="text-decoration: underline; color: red">ALL PARTICIPANTS TO THE COMPETITION AGREE TO BE BOUND BY THE
          FOLLOWING TERMS AND CONDITIONS:</a>
      </div>
      <div class="mt-2">
        2. <a style="text-decoration: underline">Duration:</a>
      </div>
      <div class="mt-2">
        2.1. This Competition shall commence on 00h01 on 1 April 2023 to 23h59 on 31 March
        2024 (the “End Date”) (“the Competition period”).
      </div>
      <div class="mt-2">
        2.2. The duration of the Competition may be extended or curtailed at the discretion of
        Vodacom.
      </div>
      <div class="mt-2">
        3. <a style="text-decoration: underline">Prizes:</a>
      </div>
      <div class="mt-2">
        3.1. Participants to the Competition stand a chance to win:
      </div>
      <div class="mt-2">
        3.1.1. their share of 1830 GB (one thousand eight hundred and thirty gigabytes) of data
        bundles broken down as follows:
      </div>
      <div class="mt-2">
        3.1.1.1. 10 (ten) winners daily over a 366 (three hundred and sixty-six) day period, each
        winning 500MB (five hundred megabytes) of data bundles worth R60.00 (sixty
        Rand) each. The total Prize is valued at R219 600.00 (two hundred and nineteen
        thousand six hundred and Rand. (“the Prize”)
      </div>
      <div class="mt-2">
        4. <a style="text-decoration: underline">Eligibility:</a>
      </div>
      <div class="mt-2">
        4.1. Only entries received during the Competition period will qualify to enter the
        Competition.
      </div>
      <div class="mt-2">
        4.2. The following requirements must be complied with during the Competition period in
        order to qualify for entry into the Competition:
      </div>
      <div class="mt-2">
        4.2.1. be a natural person;
      </div>
      <div class="mt-2">
        4.2.2. be at least 18 years and older;
      </div>
      <div class="mt-2">
        4.2.3. be a Vodacom prepaid, hybrid or post-paid subscriber during the Competition Period;
      </div>
      <div class="mt-2">
        4.2.4. reside in South Africa and be in possession of a valid South African identify
        document or South African permanent residence permit or a South African temporary
        residence permit valid for a period exceeding 3 (three) months which permits shall be
        valid as at the End Date; and
      </div>
      <div class="mt-2">
        4.2.5. use a SIM card that has been RICA registered; and
      </div>
      <div class="mt-2">
        4.2.6. be subscribed to the Stop the Clock service and playing the Stop the Clock game as
        follows:
      </div>
      <div class="mt-2">
        4.2.6.1. Play a game round which includes 10 (ten) questions within a time limit. The Top 10
        (ten) players who have answered at least 7 (seven) questions correctly with the
        lowest or fastest cumulative time in a game round for that day will win the Prize. In
        the event that more than one player answers at least 7 (seven) questions correctly
        in the same amount of time, the player with the higher number of correct questions
        answered will rank higher.
      </div>
      <div class="mt-2">
        Once or if all the requirements of paragraphs 4.2.1 to 4.2.6 are met, entry into the
        Competition will be automatic.
      </div>
      <div class="mt-2">
        4.3. Employees, directors and agents of Vodacom and the Partner (where applicable) all
        participating stores, their immediate families, life partners, business partners and
        associates and any person directly involved with the sponsoring, devising, production,
        management or marketing of this Competition are not eligible to enter this
        Competition.
      </div>
      <div class="mt-2">
        4.4. In the event that the registered user of the SIM Card or Vodacom account (i.e, the
        participant in the Competition) is not the person that is responsible for payment of the
        Vodacom account/ charges on the Vodacom account, then the Prize will be awarded
        to the person that is responsible for payment of the Vodacom account.
      </div>
      <div class="mt-2">
        4.5. Participants can win more than 1 (one) prize during the competition period.
      </div>
      <div class="mt-2">
        4.6. Standard data costs apply as per the user’s Service Provider and existing price plan
        for data usage. Vodacom shall not be liable for any costs incurred by the winners for
        claiming any Prizes, where applicable.
      </div>
      <div class="mt-2">
        5. <a style="text-decoration: underline">Prize draw</a>
      </div>
      <div class="mt-2">
        5.1. Winners will be selected at 01h00 each day of the Competition period with the first
        draw taking place on 2 April 2023 from all eligible entries who meet all the
        requirements set out in clause 4 above.
      </div>
      <div class="mt-2">
        6. <a style="text-decoration: underline">Winner Validation</a>
      </div>
      <div class="mt-2">
        6.1. All the information provided or related to this Competition shall be managed, captured
        and approved by Vodacom and/or the Partner (where applicable).
      </div>
      <div class="mt-2">
        6.2. The claim for any Prize will be subject to security and validation, and Vodacom
        reserves the right to withhold any Prize until it is satisfied that the claim by a particular
        winner is valid. The selected winners must be able to identify themselves, in a manner
        determined by Vodacom, as the qualifying participants to the Competition and are
        required to comply with the validation procedure, determined by Vodacom, to claim
        any Prizes, failing which such winner will forfeit the Prize and the Prize will be awarded
        to the next selected qualifying participant.
      </div>
      <div class="mt-2">
        6.3. In the event that Vodacom requires certain documentation to be submitted by a
        particular winner and same has not been received at the agreed cut-off date
        communicated to the winner, or no effort is made on the part of any of the winners to
        make special arrangements to meet the deadline set by Vodacom, such Prize will be
        forfeited. Vodacom then reserves the right to award the Prize to the finalist that is next
        in line.
      </div>
      <div class="mt-2">
        6.4. Vodacom and/or the Partner (where applicable) shall request the winners’ consent in
        writing to their image and/or likeness being used and published by Vodacom in
        connection with the Competition for a period of 12 (twelve) months after they have
        been announced as winners. The winners may decline the use of their image and/or
        likeness by Vodacom.
      </div>
      <div class="mt-2">
        7. <a style="text-decoration: underline">Prize Delivery</a>
      </div>
      <div class="mt-2">
        7.1. The winners will receive their data bundles via direct top up to their data bundle wallet.
        Winners will receive a confirmation SMS.
      </div>
      <div class="mt-2">
        7.2. If a winner cannot accept a Prize for any reason whatsoever, the Prize will be awarded
        to the next selected participant.
      </div>
      <div class="mt-2">
        7.3. All risks and ownership of the Prizes shall pass to the winners on transfer/delivery
        thereof and hence all of Vodacom’s obligations regarding the Competition as well as in
        regard to the Prizes shall terminate.
      </div>
      <div class="mt-2">
        8. <a style="text-decoration: underline">Personal information</a>
      </div>
      <div class="mt-2">
        8.1. User data, including personal information collected via the Competition will not be
        used for any other purpose than for execution of the Competition and will be
        processed in line with the Vodacom’s Privacy Statement which is incorporated into
        these terms and conditions. The Privacy Statement sets out how amongst other things
        Vodacom collects, use and shares subscribers personal information and how it
        protects subscriber’s privacy when processing their information.
      </div>
      <div class="mt-2">
        8.2. By continuing, a subscriber confirms that the Vodacom Privacy Statement has been
        read and understood. The latest Privacy Statement can be found on the Vodacom
        website at <a style="text-decoration: underline">https://www.vodacom.co.za/vodacom/privacy-
        policy/terms?icmp=Home/Footer/PrivacyPolicy.</a>
      </div>
      <div class="mt-2">
        9. <a style="text-decoration: underline">Prize Delivery</a>
      </div>
      <div class="mt-2">
        9.1. Nothing in these Terms and Conditions is intended to, or must be understood to,
        unlawfully restrict, limit or avoid any rights or obligations, as the case may be, created
        for either the participant or Vodacom in terms of the Consumer Protection Act, 68 of
        2008 (&quot;CPA&quot;).
      </div>
      <div class="mt-2">
        9.2. The Prize is not transferable and is not exchangeable for another Prize or cash and
        cannot be sold.
      </div>
      <div class="mt-2">
        9.3. The decision of Vodacom in respect of disputes arising out of this Competition shall be
        dealt with by Vodacom in terms of these Terms and Conditions. The decision of
        Vodacom in this regard shall be final and binding on the Parties and no
        correspondence will be entered into.
      </div>
      <div class="mt-2">
        9.4. In the event of a dispute with regard to any aspect of the Competition and/or the
        Terms and Conditions, Vodacom’s decision will be final, and binding and no
        correspondence will be entered into.
      </div>
      <div class="mt-2">
        9.5. Vodacom and/or the Partner may refuse to award a Prize if entry procedures or these
        Terms and Conditions have not been adhered to or if it detects any irregularities or
        fraudulent practices. Vodacom is entitled in its entire discretion to reject any
        participant for any reason and will not be obliged to notify participants that they have
        not been successful.
      </div>
      <div class="mt-2">
        9.6. Vodacom and/or the Partner and/or any other person or party associated with the
        Competition, their associated companies, agents, contractors and sponsors and any of
        its personnel involved in the Competition, shall not be liable whatsoever for any loss or
        damage incurred or suffered (including but not limited to direct or indirect or
        consequential loss), death or personal injury suffered or sustained arising from either
        the participant entering the Competition or from it claiming a Prize.
      </div>
      <div class="mt-2">
        9.7. Vodacom is not liable for any technical failures affecting participation and / or Prize
        redemption process of the Competition. In addition, neither Vodacom nor its agents
        shall be responsible for any loss or misdirected entries, including entries that were not
        received due to any failure of hardware, software, or other computer or technical
        systems affecting participating in and/or the Prize redemption process of the
        Competition.
      </div>
      <div class="mt-2">
        9.8. Vodacom, its directors, employees, agents, and distributors, are not responsible for
        any misrepresentation (whether written or verbal) in respect of any Prize nor in respect
        of any warranties, guarantees or undertakings given by any person other than
        Vodacom itself.
      </div>
      <div class="mt-2">
        9.9. If Vodacom elects in its sole and absolute discretion or is required by law to alter, vary
        or cancel any aspect of the Competition, it will have the right to terminate the
        Competition being offered, with immediate effect and upon written notice being
        published on the Vodacom website. In such event all participants hereby waive any
        rights which they may have against Vodacom and its associated companies, agents,
        contractors and/or sponsors.
      </div>
      <div class="mt-2">
        9.10. All information relating to the Competition which is published on any marketing
        material will form part of these Terms and Conditions. In the event of any conflict
        between such marketing material and these Terms and Conditions, these Terms and
        Conditions shall prevail.
      </div>
      <div class="mt-2">
        9.11. The Competition is also subject to, and must be read in conjunction with, Vodacom’s
        existing terms and conditions applicable to its website and mobile site respectively.,
        which terms and conditions are available on the Vodacom website.
      </div>
    </div>
    <button @click="playButtonClicked()" class="play-button mt-3 mb-5 col-10">LET'S PLAY</button>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
  methods: {
    playButtonClicked() {
      this.$router.back()
    }
  },
}
</script>

<style scoped>
.main-container {
  position: relative;
  overflow-y: scroll;
  height: 100%;
  top: 30px;
}

.header-text {
  position: relative;
  top: -30px;
  color: #FFFFFF;
  font-size: 20px;
}

.secondary-text {
  position: relative;
  top: -30px;
  margin-left: 10px;
  font-size: 12px;
  font-family: sans-serif;
}

.play-button {
  color: #FFFFFF;
  font-size: 18px;
  border: 0;
  border-radius: 16px;
  height: 55px;
  background-color: #3cc23c;
}


</style>
