<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row timer-container mt-1 col-10">
      <div class="col-3">
        <img class="col-8" src="../../assets/clock-icon.png">
      </div>
      <div class="d-flex flex-row justify-content-start col-9 timer-text">00:{{ minutes }}:{{ seconds }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClockTimer",
  data() {
    return {
      minutes: '00',
      seconds: '00',
      totalSeconds: 0,
    }
  },
  methods: {
    setTime() {
      ++this.totalSeconds;
      this.seconds = this.pad(this.totalSeconds % 60);
      this.minutes = this.pad(parseInt(this.totalSeconds / 60));
    },
    pad(value) {
      let valString = value + "";
      if (valString.length < 2) {
        return "0" + valString;
      } else {
        return valString;
      }
    },
  },
  beforeMount() {
    setInterval(this.setTime, 1000);
  }
}
</script>

<style scoped>
.timer-text {
  font-family: "Komikax", sans-serif;
  font-size: 28px;
  margin-left: 18px;
}

@media screen and (max-width: 400px) {
  .timer-text {
    font-size: 25px;
  }
}
</style>
