import {environmentConfig} from "@/config/enviromentConfig";
import axios from "axios";

export default class UtilsService {
    static async checkUsernameProfanity(username) {
        return await axios.post(`${environmentConfig.utilsService}/check-profanity`, {
                textString: username,
            },
        )
    }
}
