<template>
  <div class="d-flex flex-column align-items-center main-container pt-5" v-if="!isLoading">
    <div class="">
      <span class="header-question-text m-1 p-1 col-7 col-sm-6 col-md-10 col-lg-8 col-xl-9 ">&nbspCAN YOU STOP THE CLOCK?&nbsp</span>
      <div class="header-text m-1 p-1 col-8">Answer 10 Questions as fast as possible! <b>Top finishers win a share of
        500
        prizes daily.</b> Win 500mb data when you finish in the top 10
      </div>
      <div class="d-flex align-items-end flex-column col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11">
        <img class=""
             src="../../assets/character-illustration.png">
      </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center" >
      <div class="join-text">Join <b>Vodacom's Stop The Clock</b> for <b>R3/day.</b><br>T&Cs Apply. First Day Free
      </div>
    </div>
    <button @click="playButtonClicked()" class="play-button col-10">Subscribe</button>
    <div
        class="terms-conditions-text footer col-7 col-sm-7 col-md-6 col-lg-6 col-xl-5">
      <div @click="termsConditionsClicked()"> T&C's Apply | Subscription Service | To view subscriptions dial
        *130*997#
      </div>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center col-5 col-sm-5 col-md-4 col-lg-3 col-xl-3 mt-4" >
      <img class="swipe-text"
           src="../../assets/swipe-through-heading.png">
    </div>
    <lottie-player src="https://lottie.host/1f5a9ec5-a04c-4053-9810-55adc92ef4f3/LDE0HJwoEZ.json" background="#1C1C1C"
                   speed="1" style="width: 100%; height: 200px" loop autoplay direction="1"
                   mode="normal">

    </lottie-player>
    <div class="d-flex flex-column align-items-center  carousel-outer-container">
      <div class=" col-12 mt-5">
        <div class="carousel-container">
          <div class="col-8 m-2">
            <img class=" col-8" src="../../assets/scroll-container-1.png">
          </div>
          <div class="col-8 m-2">
            <img class="col-8" src="../../assets/scroll-container-2.png">
          </div>
          <div class="col-8 m-2">
            <img class="col-8" src="../../assets/scroll-container-3.png">
          </div>
        </div>
      </div>
      <div>
        <img class="how-to-image col-8" src="../../assets/arrows.png">
      </div>
      <div>
        <img class="fpb-logo col-6" src="../../assets/fpd-logo.png">
      </div>
      <div
          class="terms-conditions-text footer col-7 col-sm-7 col-md-6 col-lg-6 col-xl-5">
        <div @click="termsConditionsClicked()"> T&C's Apply | Subscription Service | To view subscriptions dial
          *130*997#
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import {mapState, mapMutations} from "vuex";

export default {
  name: 'Landing',
  components: {},
  computed: {
    ...mapState(['isLoading', 'user', 'brandConfig', 'dlsUserPlayedToday'])
  },
  data() {
    return {}
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setBurgerMenuIconVisible', 'setUser', 'setDlsUserPlayedToday']),
    playButtonClicked() {
      if (this.user.status === 'Active') this.$router.push('/game-play-landing');
      else if(this.user.status === 'Content Blocked') this.$router.push('/content-block');
      else if(this.user?.contentBlockedEnabled) this.$router.push('/content-block-opt-in');
      else this.$router.push('/opt-in-confirmation');
    },
    termsConditionsClicked() {
      window.location = 'https://quizinc.co.za/terms_and_conditions';
    },
    checkUserState() {
      if (this.user.status !== 'Active') {
        this.setBurgerMenuIconVisible(false);
      }
    },
  },
  beforeMount() {
    this.setIsLoading(true)
    this.setBurgerMenuIconVisible(true);
    this.checkUserState();
    this.setIsLoading(false)
  },
  mounted() {
    this.timeoutId = setTimeout(() => {
      this.$router.push('/timeout-error');
    }, 180000);
  },
  beforeUnmount() {
    clearTimeout(this.timeoutId);
  }
}
</script>
<style scoped>
@font-face {
  font-family: "Komikax";
  src: local("Komikax"),
  url(../../assets/fonts/KOMIKAX_.ttf) format("truetype");

  font-family: "Poppins-Regular";
  src: local("PoppingRg"),
  url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");

  font-family: "VodafoneRg";
  src: local("VodafoneRg"),
  url(../../assets/fonts/VodafoneRg.ttf) format("truetype");
}

.header-text {
  font-family: "VodafoneRg", sans-serif;
  font-size: 18px;
}

.header-question-text {
  font-family: "Komikax", sans-serif;
  font-size: 16px;
  border: 0;
  border-radius: 12px;
  background-color: #ff0303;
}

.play-button {
  color: #FFFFFF;
  background-color: #ff0303;
  font-family: "VodafoneRg", sans-serif;
  font-size: 18px;
  border: 0;
  border-radius: 12px;
  height: 55px;
}

.main-container {
  font-family: "Poppins-Regular", sans-serif;
  color: #FFFFFF;
  background: #1C1C1C;
}

.swipe-text {
  bottom: 0;
  position: relative;
}


.terms-conditions-text {
  font-size: 10px;
  text-decoration: underline;
  color: grey;
}

.join-text {
  font-family: "VodafoneRg", sans-serif;
  margin-bottom: 30px;
}

.fpb-logo{

}

.carousel-outer-container {
  height: max-content;
  z-index: 1;
  background: #1C1C1C;
  border: none;
}

.carousel-container {
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  z-index: 1;
}


@media screen and (max-width: 400px) {
  .main-header {
    margin-top: -20px;
  }
}

</style>
