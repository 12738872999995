import DcbService from "@/services/dcbService";
import store from "@/store";
import {handleActionClick} from "./handleActionClick";

export async function userAuthenticate(to, from, next) {
    if (store.state.user || to.name === 'TermsConditions' || to.name === 'SwitchMobileData' || to.name === 'DoiTwo') {
        store.commit('setIsLoading', false);
        return next();
    }

    store.commit('setIsLoading', true);

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') ?? localStorage.getItem('token');
    const encryptedMsisdn = urlParams.get('encryptedMsisdn') ?? localStorage.getItem('encryptedMsisdn');
    const campaignId = urlParams.get('campaignId') || urlParams.get('cid');
    const googleClickId = urlParams.get('gclid');

    if (campaignId) setCampaignId(campaignId)
    if (googleClickId) setGoogleClickId(googleClickId)
    if (encryptedMsisdn && token) {
        return await storeUserDetails(encryptedMsisdn, token, next);
    } else {
        store.commit('setIsLoading', false);
        return next({name: 'SwitchMobileData'});
    }
}

function setCampaignId(campaignId) {
    store.commit('setCampaignId', campaignId);
}

function setGoogleClickId(googleClickId) {
    store.commit('setGoogleClickId', googleClickId);
}

async function storeUserDetails(encryptedMsisdn, token, next) {
    const userDataResponse = await DcbService.getUserViaEncryptedMsisdn(encryptedMsisdn, token);
    await setUserData(userDataResponse, token, encryptedMsisdn)
    return redirectBasedOnUserStatus(userDataResponse.data, next);
}

async function setUserData(userDataResponse, token = undefined, encryptedMsisdn = undefined) {
    await DcbService.trackLoginV2(token);
    store.commit('setUser', userDataResponse.data);
    store.commit('setToken', token);
    store.commit('setEncryptedMsisdn', encryptedMsisdn);
    localStorage.setItem('token', token);
    localStorage.setItem('encryptedMsisdn', encryptedMsisdn);
}

function redirectBasedOnUserStatus(user, next) {
    store.commit('setIsLoading', false);
    if (user.status === "Active") return next({name: 'GamePlayLanding'});
    else if(user.status === 'Content Blocked') return next({name: 'ContentBlock'});
    else if(user?.contentBlockedEnabled) return next({name: 'ContentBlockOptIn'});
    else return handleActionClick(user, next);
}
