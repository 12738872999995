import {createRouter, createWebHistory} from 'vue-router'
import QuestionScreen from "@/views/pages/QuestionScreen";
import LeaderboardScreen from "@/views/pages/LeaderboardScreen";
import GameCompletedPage from "@/views/pages/GameCompletedPage";
import GameFailPage from "@/views/pages/GameFailPage";
import ManageSubscription from "@/views/pages/ManageSubscription";
import TermsConditions from "@/views/pages/TermsConditions";
import RequestOTP from "@/views/pages/onboarding/RequestOTP";
import SubmitOTP from "@/views/pages/onboarding/SubmitOTP";
import DoiOne from "@/views/pages/onboarding/DoiOne";
import DoiTwo from "@/views/pages/onboarding/DoiTwo";
import TimeoutError from "@/views/pages/onboarding/TimeoutError";
import CreateUsername from "@/views/pages/onboarding/CreateUsername";
import ContentBlock from "@/views/pages/onboarding/ContentBlock";
import {userAuthenticate} from "@/functions/userAuthenticate";
import GamePlayLanding from "@/views/pages/GamePlayLanding.vue";
import LandingPage from "@/views/pages/Landing.vue";
import SwitchMobileData from "@/views/pages/onboarding/SwitchMobileData.vue";
import InsufficientFunds from "@/views/pages/onboarding/InsufficientFunds";
import GenericError from "@/views/pages/onboarding/GenericError.vue";
import ContentBlockOptIn from "@/views/pages/onboarding/ContentBlockOptIn.vue";

const routes = [
    {
        path: '/',
        redirect: '/opt-in-confirmation',
    },
    {
        path: '/landing',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/game-play-landing',
        name: 'GamePlayLanding',
        component: GamePlayLanding,
    },
    {
        path: '/question-screen',
        name: 'QuestionScreen',
        component: QuestionScreen,
    },
    {
        path: '/leaderboard',
        name: 'LeaderboardScreen',
        component: LeaderboardScreen,
    },
    {
        path: '/game-complete',
        name: 'GameCompletedPage',
        component: GameCompletedPage,
    },
    {
        path: '/game-fail',
        name: 'GameFailPage',
        component: GameFailPage,
    },
    {
        path: '/manage-subscription',
        name: 'ManageSubscription',
        component: ManageSubscription,
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsConditions',
        component: TermsConditions,
    },
    {
        path: '/login',
        name: 'RequestOTP',
        component: RequestOTP,
    },
    {
        path: '/submit-otp',
        name: 'SubmitOTP',
        component: SubmitOTP,
    },
    {
        path: '/opt-in',
        name: 'DoiOne',
        component: DoiOne,
    },
    {
        path: '/opt-in-confirmation',
        name: 'DoiTwo',
        component: DoiTwo,
    },
    {
        path: '/content-block',
        name: 'ContentBlock',
        component: ContentBlock,
    },
    {
        path: '/create-username',
        name: 'CreateUsername',
        component: CreateUsername,
    },
    {
        path: '/timeout-error',
        name: 'TimeoutError',
        component: TimeoutError,
    },
    {
        path: '/insufficient-funds',
        name: 'InsufficientFunds',
        component: InsufficientFunds,
    },
    {
        path: '/wifi',
        name: 'SwitchMobileData',
        component: SwitchMobileData,
    },
    {
        path: '/error',
        name: 'GenericError',
        component: GenericError,
    },
    {
        path: '/content-block-opt-in',
        name: 'ContentBlockOptIn',
        component: ContentBlockOptIn,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
    }
})

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next);
})

export default router
