<template>
  <div class="d-flex flex-column align-items-center">
    <div class="header-text mt-5">MANAGE SUBSCRIPTION</div>
    <img class="col-10" src="../../assets/horizontal-line.png">
    <div class="secondary-text col-10 mt-3" v-if="unsubscribeTextOne">
      <div>You are subscribed to the daily package charged @ R3/day.</div>
      <div class="mt-3">To unsubscribe, dial *135*997# or select unsubscribe below.</div>
    </div>
    <div class="mt-3 col-10">
      <input class="col-1" @click="toggleUserLeaderboardMessaging()" type="checkbox" id="checkbox" :checked="isChecked">
      <label class="col-10" for="checkbox">I agree to receive leaderboard dropout messaging.</label>
    </div>
    <div class="secondary-text col-10 mt-3" v-if="unsubscribeTextTwo">
      <div>Are you sure you want to unsubscribe from Stop the Clock? You will no longer be able to play daily.</div>
    </div>
    <div class="d-flex flex-column align-items-center secondary-text col-10 mt-3" v-if="unsubscribeTextThree">
      <div>You have successfully unsubscribed from Stop the Clock. We are sorry to see you go. If you would like to play
        a free daily quiz select Quizinc!
      </div>
      <img class="col-7 mb-2" src="../../assets/upsell-image.png">
      <button @click="playQuizIncButtonClicked()" class="quiz-inc-red-button col-10">PLAY QUIZINC</button>
    </div>
    <div class="d-flex flex-column align-items-center button-container col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <button v-if="unsubscribeTextOne" @click="unsubscribeClicked()" class="unsubscribe-red-button col-10">
        UNSUBSCRIBE
      </button>
      <button v-if="unsubscribeTextTwo" @click="confirmUnsubscribeClicked()" class="confirm-red-button col-10">CONFIRM
      </button>
      <button v-if="unsubscribeTextOne" @click="returnHomeClicked()" class="return-home-green-button mt-3 col-10">RETURN
        HOME
      </button>
      <button v-if="unsubscribeTextTwo" @click="cancelButtonClicked()" class="cancel-green-button mt-3 col-10">CANCEL
      </button>
    </div>
  </div>
</template>

<script>
import DcbService from "@/services/dcbService";
import {mapState, mapMutations} from "vuex";
import {environmentConfig} from "@/config/enviromentConfig";
import TriviaDataService from "@/services/triviaDataService";

export default {
  name: "ManageSubscription",
  data() {
    return {
      unsubscribeTextOne: true,
      unsubscribeTextTwo: false,
      unsubscribeTextThree: false,
      isChecked: false,
      booleanConversion: 0,
    }
  },
  computed: {
    ...mapState(['token', 'encryptedMsisdn', 'user'])
  },
  methods: {
    ...mapMutations(['setBurgerMenuIconVisible', 'setUser']),
    async toggleUserLeaderboardMessaging() {
      this.isChecked = !this.isChecked
      this.conversionToBoolean()
      await TriviaDataService.updateUserLeaderboardDropoutMsgStatusV2(this.encryptedMsisdn, this.booleanConversion)
      await this.updateUser()
    },
    async updateUser() {
      const userResponse = await DcbService.getUserViaEncryptedMsisdn(this.encryptedMsisdn, this.token);
      this.setUser(userResponse.data);
    },
    conversionToBoolean() {
      if (this.isChecked === false) {
        this.booleanConversion = 0
      } else this.booleanConversion = 1
    },
    returnHomeClicked() {
      if (this.unsubscribeTextThree === true) {
        this.$router.push('/game-play-landing');
      } else this.$router.push('/game-play-landing');
    },
    unsubscribeClicked() {
      this.unsubscribeTextOne = false;
      this.unsubscribeTextTwo = true;
    },
    async confirmUnsubscribeClicked() {
      this.unsubscribeTextTwo = false;
      this.unsubscribeTextThree = true;
      const unsubscribeResponse = await DcbService.unsubscribeEncryptedUser(this.token)
      this.setUser(unsubscribeResponse.data)
    },
    cancelButtonClicked() {
      this.unsubscribeTextOne = true;
      this.unsubscribeTextTwo = false;
    },
    async playQuizIncButtonClicked() {
      this.redirectQuizInc()
    },
    redirectQuizInc() {
      const redirectUrl = `${environmentConfig.quizIncRedirectUrl}${this.encryptedMsisdn}`;
      window.open(redirectUrl, '_self');
    },
    checkUserOptInMessagingState() {
      if (this.user.optInContentMsgs) this.isChecked = true
    },
  },
  beforeMount() {
    this.setBurgerMenuIconVisible(false)
    this.checkUserOptInMessagingState()
  }
}
</script>

<style scoped>

.header-text {
  color: #FFFFFF;
  font-size: 24px;
  font-family: "Komikax", sans-serif;
}

.secondary-text {
  color: #FFFFFF;
  font-family: sans-serif;
  font-weight: 100;
  margin-left: 10px;
  font-size: 15px;
}

.button-container {
  position: fixed;
  bottom: 0;
  margin-bottom: 70px;
}

.return-home-green-button, .unsubscribe-red-button, .cancel-green-button, .confirm-red-button, .quiz-inc-red-button {
  color: #FFFFFF;
  font-size: 18px;
  border: 0;
  border-radius: 16px;
  height: 55px;
  font-family: "Komikax", sans-serif;
}

.unsubscribe-red-button, .confirm-red-button, .quiz-inc-red-button {
  background-color: #ff0303;
}

.return-home-green-button, .cancel-green-button {
  background-color: #3cc23c;
}

label {
  color: #FFFFFF;
  margin-left: 5px;
  font-size: 14px;
}

</style>
