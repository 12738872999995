<template>
  <div class="d-flex flex-column align-items-center">
    <div class="app-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <transition name="slide-burger-menu">
        <div class="menu-container" v-if="burgerMenuVisible">
          <div class="d-flex flex-column justify-content-center">
            <div class="menu-option-container col-9">
              <div>
                <div @click="homeButtonClicked()">HOME</div>
                <img class="col-12 line-img" src="./assets/horizontal-line.png">
              </div>
              <div>
                <div @click="leaderboardClicked()">LEADERBOARD</div>
                <img class="col-12 line-img" src="./assets/horizontal-line.png">
              </div>
              <div>
                <div @click="manageSubscriptionClicked()">MANAGE SUBSCRIPTION</div>
                <img class="col-12 line-img" src="./assets/horizontal-line.png">
              </div>
              <div>
                <div @click="termsConditionsClicked()">T'S & C'S</div>
                <img class="col-11 line-img" src="./assets/horizontal-line.png">
              </div>
              <div>
                <div @click="editUsernameClicked()">EDIT USERNAME</div>
                <img class="col-12 line-img" src="./assets/horizontal-line.png">
              </div>
              <div @click="burgerMenuClicked()" class="d-flex flex-column align-items-center mt-5">
                <img class="col-1 line-img" src="./assets/close-tab-icon.png">
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="`d-flex flex-column header-container-${this.bodyState}`">
        <div v-if="bodyState === 'leaderboard'" class="d-flex flex-row col-12 leaderboard-header-container">
          <img @click="burgerMenuClicked()" class="col-1 m-3" src="./assets/burger-menu-icon.png">
          <img class="col-2 stop-clock-leaderboard-image mt-2" v-if="bodyState === 'leaderboard'"
               :src="require(`./brand/${brandConfig.brand}/assets/stop-the-clock-logo.png`)"/>
        </div>
        <div v-if="bodyState === 'default'" class="d-flex flex-row col-12 clock-default-container">
          <img v-if="burgerMenuIconVisible" @click="burgerMenuClicked()" class="col-1 m-3 burger-menu-image"
               src="./assets/burger-menu-icon.png">
          <img class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-4 clock-default-image"
               :src="require(`./brand/${brandConfig.brand}/assets/stop-the-clock-logo.png`)"/>
        </div>
        <div v-if="bodyState === 'doi-two'" class="d-flex flex-row cols-12 clock-default-container">
          <img v-if="burgerMenuIconVisible" @click="burgerMenuClicked()" class="col-1 m-3 burger-menu-image"
               src="./assets/burger-menu-icon.png">
<!--          <img class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-4 clock-default-image"-->
<!--               :src="require(`./brand/${brandConfig.brand}/assets/stop-the-clock-logo.png`)"/>-->
        </div>
      </div>
      <div v-bind:class="`content-container-${this.bodyState}`" v-if="$route.name !== 'DoiTwo'">
        <LoadingOverlay v-if="this.isLoading"/>
        <router-view/>
      </div>
      <div v-bind:class="`content-container-${this.bodyState}`" v-if="$route.name === 'DoiTwo'">
        <LoadingOverlay v-if="this.isLoading"/>
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingOverlay from "@/views/components/LoadingOverlay";
import {mapState} from "vuex";
import {setBrandConfig} from "@/functions/setBrandConfig";

export default {
  components: {LoadingOverlay},
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'burgerMenuIconVisible'])
  },
  data() {
    return {
      bodyState: 'default',
      burgerMenuVisible: false,
    }
  },
  beforeMount() {
    setBrandConfig();
    this.setTabName();
  },
  methods: {
    burgerMenuClicked() {
      this.burgerMenuVisible = !this.burgerMenuVisible;
    },
    homeButtonClicked() {
      this.burgerMenuVisible = !this.burgerMenuVisible;
      this.$router.push('/game-play-landing');
    },
    leaderboardClicked() {
      this.burgerMenuVisible = !this.burgerMenuVisible;
      this.$router.push('/leaderboard');
    },
    manageSubscriptionClicked() {
      this.burgerMenuVisible = !this.burgerMenuVisible;
      this.$router.push('/manage-subscription');
    },
    termsConditionsClicked() {
      this.burgerMenuVisible = !this.burgerMenuVisible;
      window.location = 'https://quizinc.co.za/terms_and_conditions';
    },
    editUsernameClicked() {
      this.burgerMenuVisible = !this.burgerMenuVisible;
      this.$router.push('/create-username');
    },
    setTabName() {
      document.title = this.brandConfig.productTitle;
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.path === '/leaderboard') {
        this.bodyState = 'leaderboard';
      } else if (this.$route.path === '/opt-in-confirmation') {
        this.bodyState = 'doi-two';
      } else this.bodyState = 'default';
    }
  }
};
</script>
<style>

.app-container {
  height: 100vh;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.header-container-default {
  z-index: 0;
  height: 28%;
  background-image: url("./assets/main-banner.png");
  background-size: cover;
  background-position: bottom 25px center;
}

.header-container-leaderboard {
  z-index: 0;
  height: 17%;
  background-image: url("./assets/main-banner.png");
  background-size: cover;
  background-position: bottom 25px center;
}

.header-container-doi-two {
  /*z-index: 0;*/
  /*height: 25%;*/
  /*!*background-image: url("./assets/STC-Optin-3.jpg");*!*/
  /*!*background-size: cover;*!*/
  /*//background-position: bottom 25px center;*/
}

.content-container-default {
  z-index: 1;
  height: 85%;
  background-image: url("./assets/background-image.png");
  background-size: cover;
  margin-top: -55px;
}

.content-container-evina {
  z-index: 1;
  height: 85%;
  /*background-image: url("./assets/background-image.png");*/
  /*background-size: cover;*/
  margin-top: -55px;
}

.content-container-leaderboard {
  z-index: 1;
  height: 110%;
  background-image: url("./assets/background-image.png");
  background-size: cover;
  margin-top: -55px;
}

.leaderboard-header-container {
  position: relative;
}

.clock-default-container {
  position: relative;
  height: 88%;
}

.clock-default-image, .stop-clock-leaderboard-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.burger-menu-image {
  position: absolute;
}

.menu-container {
  position: absolute;
  height: 100%;
  width: 350px;
  z-index: 1;
  font-family: "Komikax", sans-serif;
  font-size: 16px;
  border-radius: 0 0 0 0;
  background-color: rgba(0, 0, 0, .75);
  color: #FFFFFF;
}

.menu-option-container {
  position: absolute;
  top: 35%;
  left: 30px;
}

.slide-burger-menu-leave-active, .slide-burger-menu-enter-active {
  transition: 1s;
  z-index: 1;
}

.slide-burger-menu-enter-from {
  transform: translateY(-100%);
}

.slide-burger-menu-leave-to {
  transform: translateY(-100%);
}

</style>
