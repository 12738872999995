import {environmentConfig} from "@/config/enviromentConfig";
import axios from "axios";

const headers = {Authorization: `Basic ${environmentConfig.authHeader}`};

export default class DcbService {
    static async trackLogin(msisdn) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/track-login`, {
                    msisdn,
                    service: "vc-fanclash-quizinc-03",
                    isWeb: 1,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async trackLoginV2(encryptedMsisdn) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/track-login/v2`, {
                    encryptedMsisdn,
                    service: "vc-fanclash-quizinc-03",
                    isWeb: 1,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async getUserViaToken(token) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/get-user-via-token`, {
                    token,
                    serviceId: "vc-fanclash-quizinc-03",
                    externalRef: 1233564,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async sendOtp(msisdn) {
        return await axios.post(`${environmentConfig.dcbService}/request-otp`, {
                msisdn: msisdn,
                serviceId: "vc-fanclash-quizinc-03",
            }, {headers}
        )
    }

    static async validateOtp(msisdn, otp) {
        return await axios.post(`${environmentConfig.dcbService}/validate-otp`, {
                msisdn: msisdn,
                serviceId: "vc-fanclash-quizinc-03",
                otp: otp,
            }, {headers}
        )
    }

    static async getUserViaMsisdn(msisdn, sessionId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/get-user-via-msisdn`, {
                    msisdn,
                    serviceId: "vc-fanclash-quizinc-03",
                    externalRef: 1233564,
                    sessionId
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async subscribeEncryptedUser(encryptedMsisdn, token, campaignId, evinaToken, contentOverride = undefined, googleClickId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/subscribe/v2`, {
                    encryptedMsisdn: encryptedMsisdn,
                    token: token,
                    serviceId: "vc-fanclash-quizinc-03",
                    "externalRef": 12335642,
                    revenuePartnerId: 33,
                    campaignId: campaignId,
                    acquisitionChannel: 'Organic-Web',
                    evinaToken: evinaToken,
                    contentOverride: contentOverride,
                    googleClickId
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async unsubscribeUser(msisdn) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/unsubscribe`, {
                    msisdn,
                    serviceId: "vc-fanclash-quizinc-03",
                    "externalRef": 12335642,
                },
                {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async unsubscribeEncryptedUser(encryptedMsisdn) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/unsubscribe/v2`, {
                    encryptedMsisdn,
                    serviceId: "vc-fanclash-quizinc-03",
                    "externalRef": 12335642,
                },
                {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async createUsername(msisdn, username) {
        return await axios.post(`${environmentConfig.dcbService}/update-username-from-msisdn`, {
                msisdn,
                serviceId: "vc-fanclash-quizinc-03",
                username
            }, {headers}
        )
    }

    static async createUsernameV2(encryptedMsisdn, username) {
        return await axios.post(`${environmentConfig.dcbService}/update-username-from-msisdn/v2`, {
                encryptedMsisdn,
                serviceId: "vc-fanclash-quizinc-03",
                username
            }, {headers}
        )
    }


    static async decryptMsisdn(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.dcbService}/decrypt-msisdn`, {
                encryptedMsisdn,
            }, {headers}
        )
    }

    static async encryptUserMsisdn(msisdn) {
        return await axios.post(`${environmentConfig.dcbService}/encrypt-msisdn`, {
                msisdn: msisdn,
            }, {headers}
        )
    }

    static async getUserViaEncryptedMsisdn(encryptedMsisdn, token) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/get-user-via-encrypted-msisdn/v2`, {
                    encryptedMsisdn: encryptedMsisdn,
                    token: token,
                    serviceId: "vc-fanclash-quizinc-03",
                    externalRef: 1233564,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async getEvinaScript(te, msisdn) {
        const response = await axios.post(`${environmentConfig.dcbService}/get-evina-script`, {
                targetElement: te,
                msisdn: msisdn,
                serviceId: "vc-fanclash-quizinc-03"
            }, {headers}
        )
        return {
            script: Buffer.from(response.data.script.toString(), "base64").toString(),
            evinaToken: response.data.token
        };
    }
}
