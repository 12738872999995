<template>
  <div v-if="leaderboardSmsReminderPopup" class="blur"></div>
  <div class="d-flex flex-column align-items-center main-container pb-3">
    <div class="header-text mt-4">LEADERBOARD</div>
    <img class="col-9" src="../../assets/horizontal-line.png">
    <div class="table-headers table-leaderboard mt-3">
      <div class="col-1">Place</div>
      <div class="col-4">Player
      </div>
      <div class=" col-1 text-center">
        Time
      </div>
      <div class="col-2 text-center">
        Correct Answers
      </div>
      <div class="col-2 text-center">
        Data Won
      </div>
    </div>
    <div class="table-headers table-leaderboard-row" v-for="user in roundLeaderboardArray" :key="user.id">
      <div class="d-flex flex-row align-items-center col-1">{{ roundLeaderboardArray.indexOf(user) + 1 }}</div>
      <div class="d-flex flex-row align-items-center player-icon-text col-4">
        <img class="user-icon" src="../../assets/user-icon.png">
        <div v-if="user.Username">{{ user.Username }}</div>
        <div v-if="user.Username === null">Player {{ user.Msisdn.slice(-3) }}</div>
      </div>
      <div class="d-flex flex-row align-items-center col-1 justify-content-center">{{ user.GameDurationSeconds }}s</div>
      <div class="d-flex flex-row align-items-center col-2 justify-content-center">{{ user.CorrectAnswers }}</div>
      <div v-if="brandConfig.showDataWon" class="d-flex flex-row align-items-center col-2 justify-content-center">{{ amountWon }}</div>
    </div>
    <div @click="questionMarkClicked()" class="question-leaderboard mt-2 mb-3">
      <div class="question-mark">
        ?
      </div>
    </div>
    <div class="d-flex flex-column align-items-center col-12">
      <button v-if="dlsUserPlayedToday === undefined" @click="playAgainClicked()" class="play-again-button col-9">PLAY QUIZ</button>
      <div @click="homeButtonClicked()" class="mt-2 home-button"><span>&#10229;</span> Home</div>
    </div>
    <div class="d-flex flex-column align-items-center mt-3">
      <img class="col-5" src="../../assets/stc-quizinc-logo.png">
    </div>
    <div v-if="playAgainPopUp" class="d-flex flex-column align-items-center popup-container col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <div class="d-flex flex-column align-items-center red-container">
        <div class="popup-header-text text-center col-10 mt-2 mb-2">YOU ARE ABOUT TO PLAY...</div>
        <img class="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3 mb-3" src="../../assets/horizontal-line.png">
        <div class="secondary-popup-text col-10 mb-2">Once you click 'Continue' the quiz will begin.</div>
      </div>
      <button @click="continueButtonClicked()" class="continue-button col-12 mt-2">CONTINUE</button>
    </div>
    <div v-if="howToWinPopup" class="d-flex flex-column align-items-center popup-container col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <div class="d-flex flex-column align-items-center red-container">
        <div class="popup-header-text text-center col-10 mt-2 mb-2">HOW TO MAKE THE LEADERBOARD</div>
        <img class="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3 mb-3" src="../../assets/horizontal-line.png">
        <div class="secondary-popup-text col-10 mb-4">Only users who score 7/10 or more will make the leaderboard. The players with the 10 quickest times will all WIN!</div>
      </div>
      <button @click="backButtonClicked()" class="back-button col-12 mt-2">BACK</button>
    </div>
    <div v-if="leaderboardSmsReminderPopup" class="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3 leaderboard-reminder-popup">
      <LeaderboardReminder></LeaderboardReminder>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import TriviaDataService from "@/services/triviaDataService";
import {environmentConfig} from "@/config/enviromentConfig";
import LeaderboardReminder from "@/views/components/LeaderboardReminder";

export default {
  name: "LeaderboardScreen",
  data() {
    return {
      playAgainPopUp: false,
      howToWinPopup: false,
      roundLeaderboardArray: undefined,
      amountWon: environmentConfig.prizeWonLeaderboard,
      userInTopTen: undefined,
    }
  },
  components: {LeaderboardReminder},
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'dlsUserPlayedToday', 'user', 'leaderboardSmsReminderPopup', 'token'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setDlsUserPlayedToday', 'setLeaderboardSmsReminderPopup']),
    homeButtonClicked() {
      this.$router.push('/game-play-landing')
    },
    playAgainClicked() {
      this.playAgainPopUp = !this.playAgainPopUp
    },
    async getRoundLeaderboard() {
      let roundLeaderboardResponse = await TriviaDataService.getRoundLeaderboardV2(this.token)
      this.roundLeaderboardArray = roundLeaderboardResponse.data.recordset;
    },
    backButtonClicked() {
      this.howToWinPopup = !this.howToWinPopup
    },
    questionMarkClicked() {
      this.howToWinPopup = !this.howToWinPopup
    },
    continueButtonClicked() {
      this.$router.push('/question-screen')
    },
    toggleLeaderboardReminderPopup() {
      if (this.user.optInContentMsgs === undefined || this.user.optInContentMsgs === null) {
        this.setLeaderboardSmsReminderPopup(true)
      }
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.getRoundLeaderboard();
    this.toggleLeaderboardReminderPopup();
    this.setIsLoading(false)
  },
  watch: {
    '$store.state.leaderboardSmsReminderPopup': function () {
      if (this.$store.state.leaderboardSmsReminderPopup === false) {
        this.setLeaderboardSmsReminderPopup(false)
      }
    }
  }
}
</script>

<style scoped>
.blur {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
  top: 0;
  height: 100vh;
  width: 100vh;
  z-index: 1;
}

.main-container {
  position: relative;
}

.table-headers {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
}

.question-leaderboard {
  position: relative;
  margin-top: -15px;
  background-color: #ff0303;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.question-mark {
  position: absolute;
  margin-left: 15px;
  margin-top: 2px;
  font-size: 20px;
}

.popup-container, .leaderboard-reminder-popup {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.red-container {
  background-color: #ff0303;
  border-radius: 10px;
}

.header-text, .popup-header-text {
  font-family: "Komikax", sans-serif;
  color: #FFFFFF;
  font-size: 26px;
}

.table-leaderboard, .table-leaderboard-row {
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  background-color: #1C1C1C;
  width: 95%;
}

.table-leaderboard-row {
  margin-top: 8px;
  margin-bottom: 8px;
}

.user-icon {
  height: 25px;
  margin-right: 5px;
}

.play-again-button {
  font-family: "Komikax", sans-serif;
  color: #FFFFFF;
  background-color: #3cc23c;
  font-size: 16px;
  border: 0;
  border-radius: 10px;
  height: 40px;
  margin-top: -5px;
}

.home-button {
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
}

.secondary-popup-text {
  font-size: 18px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
}

.bold-coin-text {
  font-weight: 800;
}

.continue-button, .back-button {
  height: 50px;
  font-size: 16px;
  border: 0;
  border-radius: 10px;
  color: #000000;
}

.leaderboard-round-info {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 300;
}

@media screen and (max-width: 400px) {
  .user-icon {
    height: 20px;
    margin-right: 5px;
  }

  .table-headers {
    font-size: 10px;
  }
}

</style>
