import store from "@/store";
import {environmentConfig} from "../config/enviromentConfig";
import DcbService from "../services/dcbService";

export async function handleActionClick(user, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');
    const evinaToken = urlParams.get('evinaToken') ?? localStorage.getItem('evinaToken') ?? store.state.evinaToken;
    if (!action) return next({name: 'DoiTwo'});
    if (action === 'accept') await handleAcceptButton(evinaToken, user, next);
    else await handleExitButton(evinaToken, user);
}


async function handleExitButton(evinaToken, user) {
    store.commit('setIsLoading', true);
    if (store.state.campaignId === 81 || store.state.campaignId === '81') {
        window.open(`${environmentConfig.dailyFiveRedirectUrl}${store.state.msisdn}`, '_self');
    } else {
        let contentEnabled;
        if (user?.contentBlockedEnabled) contentEnabled = "CBADP"
        else contentEnabled = "UDP"
        await DcbService.subscribeEncryptedUser(store.state.encryptedMsisdn, store.state.token, store.state.campaignId, evinaToken, contentEnabled, store.state.googleClickId);
        store.commit('setIsLoading', false);
        window.location = 'https://www.vodacom.co.za/vodacom/shopping/v/add-to-bill';
    }
}

async function handleAcceptButton(evinaToken, user, next) {
    store.commit('setIsLoading', true);
    let contentEnabled;
    if (user?.contentBlockedEnabled) contentEnabled = "CBAAP";
    else contentEnabled = "UAP"
    const subUserResponse = await DcbService.subscribeEncryptedUser(store.state.encryptedMsisdn, store.state.token, store.state.campaignId, evinaToken, contentEnabled, store.state.googleClickId);
    handleRouting(subUserResponse.data, next)
}

function handleRouting(userSubData, next) {
    if (userSubData.result === true) {
        store.commit('setIsLoading', false);
        return next({name: 'CreateUsername'});
    } else handleSubErrorResponse(userSubData);
}

function handleSubErrorResponse(userSubData, next) {
    if (userSubData.reason.includes('restricts')) {
        store.commit('setIsLoading', false);
        return next({name: 'ContentBlock'});
    } else if (userSubData.status === 'Insufficient Funds') {
        store.commit('setIsLoading', false);

    } else {
        store.commit('setIsLoading', false);
        return next({name: 'GenericError'});
    }
}
