<template>
  <div class="d-flex flex-column align-items-center main-container">
    <img src="../../../assets/username-icon.png" class="username-icon col-5 col-sm-7 col-md-6 col-lg-5 col-xl-4">
    <div class="d-flex flex-column justify-content-start header-text col-8">
      <div>WOOHOO!</div>
      <div class="paragraph-text mt-2 mb-2">You have successfully subscribed to Stop the Clock! Now it's time to get
        started!
      </div>
      <div class="paragraph-text">Enter a username that will be displayed on the leaderboard. Username must be a mixture
        of alphabetical characters ranging from 6-14 characters.
      </div>
    </div>
    <input @keyup.enter="submitUsernameClicked(playerUsername)" class="col-10 enter-username-input"
           placeholder="Enter username" v-model="playerUsername">
    <button @click="submitUsernameClicked(playerUsername)" class="col-10 mt-3 submit-button">SUBMIT</button>
    <div class="mt-2 mb-1 username-error-text" v-if="validUsername === false">Invalid Username</div>
    <div v-if="brandConfig.showTermsConditions" @click="termsConditionsClicked()"
         class="footer col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3">T&C's Apply | Subscription Service | R3/day | First
      Day Free
      | To view subscription dial *130*997#
    </div>
  </div>
</template>

<script>
import DcbService from "@/services/dcbService";
import {mapMutations, mapState} from "vuex";
import TriviaDataService from "@/services/triviaDataService";
import UtilsService from "@/services/utilsService";

export default {
  name: "CreateUsername",
  computed: {
    ...mapState(['token', 'encryptedMsisdn', 'brandConfig'])
  },
  data() {
    return {
      playerUsername: undefined,
      validUsername: undefined,
    }
  },
  methods: {
    ...mapMutations(['setUser']),
    async submitUsernameClicked(playerUsername) {
      await this.authenticateUsername(playerUsername);
      if (this.validUsername) {
        await DcbService.createUsernameV2(this.token  , playerUsername)
        const userResponse = await DcbService.getUserViaEncryptedMsisdn(this.encryptedMsisdn, this.token);
        this.setUser(userResponse.data);
        await TriviaDataService.upsertUsernameV2(this.token, userResponse.data.username);
        this.$router.push('/game-play-landing')
      }
    },
    async profanityChecker(playerUsername) {
      const profanityCheckResponse = await UtilsService.checkUsernameProfanity(playerUsername)
      this.usernameIncludesProfanity = profanityCheckResponse.data
    },
    async authenticateUsername(playerUsername) {
      await this.profanityChecker(playerUsername);

      const usernameLength = playerUsername.length;
      const validLength = usernameLength >= 6 && usernameLength <= 14;
      const validProfanity = !this.usernameIncludesProfanity.containsProfanity;

      this.validUsername = validLength && validProfanity;
    },
    termsConditionsClicked() {
      window.location = 'https://quizinc.co.za/terms_and_conditions';
    },
  },
}
</script>

<style scoped>
.main-container {
  color: #FFFFFF;
  padding-top: 40px;
}

.footer {
  display: flex;
  text-align: center;
  position: fixed;
  bottom: 5px;
  font-family: "Arial", sans-serif;
  font-size: 12px;
  color: #808080FF;
}

.header-text {
  font-size: 22px;
}

.paragraph-text {
  font-family: "Arial", sans-serif;
  font-size: 15px;
}

.enter-username-input {
  height: 55px;
  border-radius: 12px;
  font-family: "Arial", sans-serif;
  margin-top: 15px;
}

::placeholder {
  font-family: "Arial", sans-serif;
  padding-left: 10px;
}

.submit-button {
  height: 60px;
  border-radius: 12px;
  background-color: #3cc23c;
  color: #FFFFFF;
  border: 0;
}

.username-error-text {
  font-size: 20px;
  font-family: "Arial", sans-serif;
  color: #ff0303;
}

@media screen and (max-width: 400px) {
  .paragraph-text {
    font-size: 12px;
  }

  .username-icon {
    margin-top: -30px;
  }

  .enter-username-input {
    height: 50px;
  }

  .submit-button {
    height: 55px;
  }

  .footer {
    display: none;
  }
}
</style>
