<template>
  <div class="d-flex flex-column text-center align-items-center main-container">
    <div class="col-10 header-text mb-3"> WIN <span class="red-text">500MB DATA</span> DAILY IF YOU FINISH IN THE TOP 10 WITH VODACOM'S STOP THE CLOCK!</div>
    <div class="secondary-text col-10">Click <span class="bold-text">Yes</span> to subscribe to <span class="bold-text">Stop the clock</span>
      for <span class="bold-text">R3/day</span>. First Day Free.
    </div>
    <button @click="yesButtonClicked()" class="yes-button mt-4 col-10">YES</button>
    <button @click="cancelButtonClicked()" class="cancel-button mt-3 col-10">CANCEL</button>
    <div v-if="brandConfig.showTermsConditions" class="d-flex flex-column align-items-center footer col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2">
      <img class="col-5 mb-3" src="../../../assets/stc-quizinc-logo.png">
      <div @click="termsConditionsClicked()"> T&C's Apply | Subscription Service | R3/day | First Day Free</div>
    </div>
  </div>
</template>

<script>
import DcbService from "@/services/dcbService";
import {mapState} from "vuex";
import {environmentConfig} from "@/config/enviromentConfig";

export default {
  name: "DoiOne",
  computed: {
    ...mapState(['msisdn', 'brandConfig', 'campaignId'])
  },
  methods: {
    async yesButtonClicked() {
      this.$router.push('/opt-in-confirmation');
    },
    async cancelButtonClicked() {
      this.routeBasedOnCampaignId()
    },
    routeBasedOnCampaignId() {
      if (this.campaignId === 81 || this.campaignId === '81') {
        window.open(`${environmentConfig.dailyFiveRedirectUrl}${this.msisdn}`, '_self');
      } else {
        this.$router.push('/login') //need a page for this
      }
    },
    termsConditionsClicked() {
      window.location = 'https://quizinc.co.za/terms_and_conditions';
    },
  },
}
</script>

<style scoped>
.main-container {
  color: #FFFFFF;
  padding-top: 40px;
}

.footer {
  display: flex;
  text-align: center;
  position: fixed;
  bottom: 5px;
  font-family: "Arial", sans-serif;
  font-size: 12px;
  color: #808080FF;
}

.header-text {
  font-size: 18px;
}

.secondary-text {
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.bold-text {
  font-weight: 700;
}

.yes-button, .cancel-button {
  color: #FFFFFF;
  font-size: 18px;
  border: 0;
  border-radius: 12px;
  height: 55px;
}

.yes-button {
  background-color: #ff0303;
}

.cancel-button {
  background-color: #808080FF;;
}
</style>
