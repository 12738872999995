<template>
  <div class="d-flex flex-column text-center align-items-center main-container">
    <img class="col-5" src="../../../assets/restricted-icon.png">
    <div class="col-9">Your session has timed out, please click below to retry.</div>
    <div class="d-flex flex-column align-items-center footer col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2">
      <img class="col-5 mb-3" src="../../../assets/stc-quizinc-logo.png">
      <button @click="onRetryClicked()" class="play-button col-10">Retry</button>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TimeoutError",
  computed: {
    ...mapState(['user'])
  },
  methods: {
    onRetryClicked() {
      if (this.user.status === 'Active') this.$router.push('/game-play-landing');
      else this.$router.push('/landing');
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: "VodafoneRg";
  src: local("VodafoneRg"),
  url(../../../assets/fonts/VodafoneRg.ttf) format("truetype");
}
.main-container {
  color: #FFFFFF;
  padding-top: 40px;
  font-family: "Arial", sans-serif;
}

.footer {
  display: flex;
  text-align: center;
  position: fixed;
  bottom: 5px;
  font-size: 12px;
  color: #808080FF;
}

.play-button {
  color: #FFFFFF;
  background-color: #ff0303;
  font-family: "VodafoneRg", sans-serif;
  font-size: 18px;
  border: 0;
  border-radius: 12px;
  height: 55px;
}
</style>
