<template>
  <div class="d-flex flex-column align-items-center leaderboard-reminder-container pt-5 pb-3">
    <img class="col-6 col-sm-5 col-md-5 col-lg-5 col-xl-6 popup-img" src="../../assets/leaderboard-reminder-popup.png">
    <div class="col-11">
      <div v-if="firstLeaderboardReminder">
        <div class="reminder-text">
          Make the top 10 & get sent a daily SMS if you drop out.
        </div>
        <div class="terms-conditions">
          T&C's Apply
        </div>
        <div class="d-flex flex-row justify-content-between mt-2">
          <button @click="noButtonClicked()" class="col-5 sms-reminder-button">NO</button>
          <button @click="yesButtonClicked()" class="col-5 sms-reminder-button">YES</button>
        </div>
      </div>
      <div v-if="!firstLeaderboardReminder">
        <div class="reminder-text">
          Got it! You will be sent a daily SMS if you fall out of the top 10.
        </div>
        <div class="d-flex flex-column align-items-center mt-2">
          <button @click="closePopupButtonClicked()" class="col-5 sms-reminder-button">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import TriviaDataService from "@/services/triviaDataService";
import DcbService from "@/services/dcbService";

export default {
  name: "LeaderboardReminder",
  computed: {
    ...mapState(['token', 'encryptedMsisdn'])
  },
  data() {
    return {
      firstLeaderboardReminder: true,
    }
  },
  methods: {
    ...mapMutations(['setLeaderboardSmsReminderPopup', 'setUser']),
    closePopupButtonClicked() {
      this.setLeaderboardSmsReminderPopup(false)
    },
    async yesButtonClicked() {
      await TriviaDataService.updateUserLeaderboardDropoutMsgStatusV2(this.token, 1)
      await this.updateUser()
      this.firstLeaderboardReminder = !this.firstLeaderboardReminder
    },
    async noButtonClicked() {
      await TriviaDataService.updateUserLeaderboardDropoutMsgStatusV2(this.token, 0)
      await this.updateUser()
    },
    async updateUser() {
      const userResponse = await DcbService.getUserViaEncryptedMsisdn(this.encryptedMsisdn, this.token);
      this.setUser(userResponse.data);
    },
  },
}
</script>

<style scoped>
.leaderboard-reminder-container {
  position: relative;
  background-color: #EEEEEE;
  border-radius: 14px;
  font-family: 'Roboto', sans-serif;
}

.reminder-text, .terms-conditions {
  font-weight: 600;
  color: #000000;
}

.reminder-text {
  font-size: 20px;
}

.terms-conditions {
  font-size: 14px;
}

.sms-reminder-button {
  color: #FFFFFF;
  border: 0;
  border-radius: 8px;
  background-color: #E60000;
  font-weight: 600;
  height: 40px;
  font-size: 17px;
}

.popup-img {
  position: absolute;
  top: -63px;
}

@media screen and (max-width: 400px) {
  .popup-img {
    position: absolute;
    top: -50px;
  }
}

</style>
