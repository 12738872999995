import {createStore} from 'vuex'

export default createStore({
    state: {
        isLoading: false,
        userScore: false,
        user: undefined,
        encryptedMsisdn: undefined,
        token: undefined,
        msisdn: undefined,
        userQuizFinishingTime: undefined,
        brandConfig: undefined,
        burgerMenuIconVisible: false,
        campaignId : undefined,
        googleClickId: undefined,
        dlsUserPlayedToday: undefined,
        leaderboardSmsReminderPopup: false,
        evinaToken: undefined
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setUserScore(state, userScore) {
            state.userScore = userScore
        },
        setUser(state, user) {
            state.user = user
        },
        setMsisdn(state, msisdn) {
            state.msisdn = msisdn
        },
        setEncryptedMsisdn(state, encryptedMsisdn) {
            state.encryptedMsisdn = encryptedMsisdn
        },
        setToken(state, token) {
            state.token = token
        },
        setUserQuizFinishingTime(state, userQuizFinishingTime) {
            state.userQuizFinishingTime = userQuizFinishingTime
        },
        setBrandConfig(state, brandConfig) {
            state.brandConfig = brandConfig
        },
        setBurgerMenuIconVisible(state, burgerMenuIconVisible) {
            state.burgerMenuIconVisible = burgerMenuIconVisible
        },
        setCampaignId(state, campaignId) {
            state.campaignId = campaignId
        },
        setGoogleClickId(state, googleClickId) {
            state.googleClickId = googleClickId
        },
        setDlsUserPlayedToday(state, dlsUserPlayedToday) {
            state.dlsUserPlayedToday = dlsUserPlayedToday
        },
        setLeaderboardSmsReminderPopup(state, leaderboardSmsReminderPopup) {
            state.leaderboardSmsReminderPopup = leaderboardSmsReminderPopup
        },
        setEvinaToken(state, evinaToken) {
            state.evinaToken = evinaToken
        },
    },
    actions: {},
    modules: {}
})
