<template>
  <div class="d-flex flex-column text-center align-items-center main-container">
    <div class="col-10 header-text mb-3"> WIN <span class="red-text">500MB DATA</span> DAILY IF YOU FINISH IN THE TOP 10 WITH VODACOM'S STOP THE CLOCK!</div>
    <div class="col-9 secondary-text mb-2">Enter your phone number below to join:</div>
    <input @keyup.enter="continueButtonClicked()" type="text" placeholder="Enter your number" v-model="msisdn" class="msisdn-input col-10">
    <button @click="continueButtonClicked()" class="continue-button mt-3 col-10">CONTINUE</button>
    <div v-if="isValidNumber === false" class="invalid-number-text mt-2">Invalid number</div>
    <div class="enter-number-text col-9 mt-1">By entering your number you agree to our <span @click="termsConditionsClicked()" class="underline-text">privacy policy</span> and <span
        @click="termsConditionsClicked()" class="underline-text">terms and conditions</span></div>
    <div v-if="brandConfig.showTermsConditions" class="d-flex flex-column align-items-center footer col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2">
      <img class="col-5 mb-3" src="../../../assets/stc-quizinc-logo.png">
      <div @click="termsConditionsClicked()"> T&C's Apply | Subscription Service | R3/day | First Day Free</div>
    </div>
  </div>
</template>

<script>
import DcbService from "@/services/dcbService";
import {mapMutations, mapState} from "vuex";

export default {
  name: "RequestOTP",
  computed: {
    ...mapState(['brandConfig'])
  },
  data() {
    return {
      msisdn: undefined,
      isValidNumber: undefined,
    }
  },
  methods: {
    ...mapMutations(['setMsisdn', 'setUser']),
    async continueButtonClicked() {
      if (this.isCellNumberValid(this.msisdn)) {
        const noPlus = this.msisdn.replace(/[+]/g, "");
        const noZero = noPlus.replace(/^[0]/, "27");
        this.msisdn = noZero.replace(/\s/g, '')
        await DcbService.sendOtp(this.msisdn)
        this.setMsisdn(this.msisdn)
        this.setUser(this.msisdn)
        localStorage.setItem('msisdn', this.msisdn);
        this.$router.push('/submit-otp');
      } else {
        this.msisdn = '';
      }
    },
    isCellNumberValid(cellNumber) {
      if (cellNumber.length === 10 || cellNumber.length === 11) {
        let regex = /(?:^[0-9\s]{1,9}?$|(?:0|\+27|27)\d{2}[\s-]?\d{3}[\s-]?\d{4})/;
        if (regex.test(cellNumber)) {
          this.isValidNumber = true;
          return true
        } else {
          this.isValidNumber = false;
          return false
        }
      } else {
        this.isValidNumber = false;
      }
    },
    termsConditionsClicked() {
      window.location = 'https://quizinc.co.za/terms_and_conditions';
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: "Komikax";
  src: local("Komikax"),
  url(../../../assets/fonts/KOMIKAX_.ttf) format("truetype");
}

.main-container {
  color: #FFFFFF;
  padding-top: 40px;
}

.header-text {
  font-family: "Komikax", sans-serif;
  font-size: 18px;
}

.red-text {
  color: #ff0303;
}

.secondary-text {
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.bold-text {
  font-weight: 700;
}

.enter-number-text {
  font-family: "Arial", sans-serif;
  font-size: 12px;
  color: #808080FF;
}

.msisdn-input {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #808080FF;
  border-radius: 12px;
  height: 50px;
  padding-left: 5px;
}

::placeholder {
  font-size: 16px;
  padding-left: 5px;
}

.continue-button {
  color: #FFFFFF;
  background-color: #ff0303;
  font-size: 18px;
  border: 0;
  border-radius: 12px;
  height: 55px;
}

.footer {
  display: flex;
  text-align: center;
  position: fixed;
  bottom: 5px;
  font-family: "Arial", sans-serif;
  font-size: 12px;
  color: #808080FF;
}

.invalid-number-text {
  font-family: "Arial", sans-serif;
  font-size: 14px;
}

.underline-text {
  text-decoration: underline;
}

</style>
