<template>
  <div class="d-flex flex-column text-center align-items-center main-container">
    <div class="col-10 header-text mb-3"> To Continue, please switch to your <span class="bold-text">Mobile Data</span> & select refresh.</div>
    <div class="col-9 secondary-text mb-2">Or Dial *117*76#</div>
    <button @click="continueButtonClicked()" class="continue-button mt-3 col-10">Refresh</button>
    <div v-if="brandConfig.showTermsConditions" class="d-flex flex-column align-items-center footer col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2">
      <div @click="termsConditionsClicked()"> T&C's Apply | Subscription Service | R3/day | First Day Free</div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "SwitchMobileData",
  computed: {
    ...mapState(['brandConfig'])
  },
  data() {
    return {
      msisdn: undefined,
      isValidNumber: undefined,
    }
  },
  methods: {
    ...mapMutations(['setMsisdn', 'setUser']),
    async continueButtonClicked() {
      window.location = 'http://penroseza.com?game=stoptheclock'
    },
    termsConditionsClicked() {
      window.location = 'https://quizinc.co.za/terms_and_conditions';
    },
  },
}
</script>

<style scoped>
@font-face {

  font-family: "VodafoneRg";
  src: local("VodafoneRg"),
  url(../../../assets/fonts/VodafoneRg.ttf) format("truetype");
}

.main-container {
  color: #FFFFFF;
  padding-top: 40px;
}

.header-text {
  font-family: "VodafoneRg", sans-serif;
  font-size: 18px;
}

.secondary-text {
  font-family: "VodafoneRg", sans-serif;
  font-size: 16px;
}

.bold-text {
  font-weight: 700;
}

::placeholder {
  font-size: 16px;
  padding-left: 5px;
}

.continue-button {
  font-family: "Arial", sans-serif;
  color: #FFFFFF;
  background-color: #ff0303;
  font-size: 18px;
  border: 0;
  border-radius: 12px;
  height: 55px;
}

.footer {
  display: flex;
  text-align: center;
  position: fixed;
  bottom: 5px;
  font-family: "Arial", sans-serif;
  font-size: 12px;
  color: #808080FF;
}

</style>
