<template>
  <div class="d-flex flex-column text-center align-items-center main-container">
    <!--    <div class="col-10 header-text mb-3"> Get <span class="bold-text">Vodacom Quizinc Stop The Clock</span> for <span-->
    <!--        class="bold-text">R3/day</span> on Your Vodacom Account.<br>T&Cs Apply. First Day Free.-->
    <!--    </div>-->
    <!--    <div @click="termsConditionsClicked()"-->
    <!--         class="d-flex flex-column align-items-center col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2 secondary-text">Term &-->
    <!--      Conditions-->
    <!--    </div>-->
    <!--    <button @click="confirmButtonClicked()" id="subscribe_button" class="cptpl_subscribe confirm-button mt-4 col-10">ACCEPT</button>-->
    <!--    <button @click="cancelButtonClicked()" class="cancel-button mt-3 col-10">DECLINE</button>-->
    <input type="hidden" value="20221206" name="cptpl_template" class="test cptpl_template"/>
    <div id="page" class="cptpl_page">
      <div id="header-image">
        <img crossorigin="anonymous"
             class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-4 clock-img cptpl_logo cptpl_logo_static"
             src="https://fanclash.blob.core.windows.net/dcb/stop-the-clock/STC-Optin-3.jpg"/>
      </div>
      <div id="wording">
        <span class="col-10 header-text mb-3 text cptpl_service">Get <b>Vodacom Quizinc Stop The Clock</b></span><span
          class="text"><span class="cptpl_price"> for <b>R3/day</b></span> <span
          class="cptpl_account">on Your Vodacom Account.</span><br>T&C's Apply. First Day Free</span>
      </div>
      <div id="mentions">
        <span class="text charge cptpl_bill"><br></span>
        <span class="text links clearfix">
          <a href="https://quizinc.co.za/terms_and_conditions" class="terms cptpl_terms">Terms &amp; Conditions</a>
        </span>
      </div>
      <div id="actions">
        <noscript>
          <h1>Please enable javascript!</h1>
        </noscript>
        <button @click="confirmButtonClicked()" id="subscribe_button"
                class="cptpl_subscribe confirm-button click-button mt-4 col-10">ACCEPT
        </button>
      </div>
      <div id="exit">
        <span class="exit">
          <button @click="cancelButtonClicked()"
                  class="exit cptpl_exit cancel-button click-button mt-3 col-10">EXIT</button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DcbService from "@/services/dcbService";
import {mapMutations, mapState} from "vuex";

export default {
  name: "DoiTwo",
  computed: {
    ...mapState(['msisdn', 'encryptedMsisdn', 'token', 'campaignId', 'brandConfig', 'user', 'googleClickId'])
  },
  data() {
    return {
      evinaToken: undefined
    }
  },
  methods: {
    ...mapMutations(['setCampaignId', 'setIsLoading', 'setEvinaToken']),
    async confirmButtonClicked() {
      this.setIsLoading(true);
      this.redirectToHE('accept');
    },
    async cancelButtonClicked() {
      this.setIsLoading(true);
      this.redirectToHE('cancel');
    },
    redirectToHE(action) {
      const campaignParameters = this.getCampaignParamters();
      window.location = `http://penroseza.com/?game=stoptheclock&action=${action}&cid=${campaignParameters.campaignId}&gclid=${campaignParameters.googleClickId}&evinaToken=${this.evinaToken}`
    },
    termsConditionsClicked() {
      window.location = 'https://quizinc.co.za/terms_and_conditions';
    },
    getCampaignParamters() {
      const urlParams = new URLSearchParams(window.location.search);
      const campaignId = urlParams.get('campaignId') ?? urlParams.get('cid') ?? 0;
      const googleClickId = urlParams.get('gclid');
      return {
        campaignId,
        googleClickId
      }
    },
    async embedEvinaScript() {
      const evinaScriptResponse = await DcbService.getEvinaScript(".click-button", this.encryptedMsisdn);
      this.evinaToken = evinaScriptResponse.evinaToken;
      this.setEvinaToken(evinaScriptResponse.evinaToken);
      localStorage.setItem('evinaToken', evinaScriptResponse.evinaToken);
      const evinaScript = document.createElement('script');
      evinaScript.text = evinaScriptResponse.script.toString();
      document.head.appendChild(evinaScript)
      const event = new Event('DCBProtectRun');
      document.dispatchEvent(event);
    },
  },
  async beforeMount() {
    await this.embedEvinaScript();
  },
  mounted() {
    this.timeoutId = setTimeout(() => {
      this.$router.push('/timeout-error');
    }, 180000);
  },
  beforeUnmount() {
    clearTimeout(this.timeoutId);
  }
}
</script>

<style scoped>
.main-container {
  color: #000000;
  /*padding-top: 40px;*/
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.terms {
  font-family: "Arial", sans-serif;
  font-size: 12px;
  text-decoration: underline;
  color: #000000;

}

.confirm-button, .cancel-button {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  border: 0;
  border-radius: 12px;
  height: 65px;
}

.confirm-button {
  background-color: #ff0303;
}

.cancel-button {
  background-color: #808080FF;;
}

.exit {
  background-color: #E5E5E5;
  color: black;
}

.clock-img {
  width: 100%;
}
</style>
