<template>
  <div class="d-flex flex-column text-center align-items-center main-container pt-5" v-if="!isLoading">
    <div class="main-header col-12">
      <div class="col-12">WELL DONE!</div>
      <img class="col-9 horizontal-line" src="../../assets/horizontal-line.png">
    </div>
    <div class="secondary-text">You answered all the questions in:</div>
    <img class="col-5 mt-3" src="../../assets/trophy-icon.png">
    <div class="mt-3 completed-time-text">{{ userQuizFinishingTime }} SECONDS</div>
    <div class="mt-1 secondary-text">Score: {{ userScore }}/10</div>
    <button @click="leaderboardClicked" class="leaderboard-button col-10">VIEW LEADERBOARD</button>
    <div @click="homeButtonClicked()" class="mt-2 home-button"><span>&#10229;</span> Home</div>
    <div class="mt-1">
      <img class="col-5" src="../../assets/stc-quizinc-logo.png">
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "GameCompletedPage",
  computed: {
    ...mapState(['isLoading', 'userQuizFinishingTime', 'userScore'])
  },
  data() {
    return {}
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    homeButtonClicked() {
      this.$router.push('/game-play-landing')
    },
    leaderboardClicked() {
      this.$router.push('/leaderboard')
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: "Komikax";
  src: local("Komikax"),
  url(../../assets/fonts/KOMIKAX_.ttf) format("truetype");
}

.main-container {
  font-family: "Komikax", sans-serif;
  color: #FFFFFF;
}

.main-header, .completed-time-text {
  font-size: 30px;
}

.horizontal-line {
  margin-top: -44px;
}

.secondary-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  margin-top: -36px;
  font-size: 18px;
}

.leaderboard-button {
  color: #FFFFFF;
  background-color: #3cc23c;
  font-size: 16px;
  border: 0;
  border-radius: 12px;
  height: 60px;
  margin-top: 40px;
}

.home-button {
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
}

@media screen and (max-width: 400px) {
  .leaderboard-button {
    margin-top: 15px;
  }
}

</style>
