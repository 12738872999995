import {brandConfigObjectMap} from "@/brand/brandConfig";

export const brandConfigMap = new Map();

brandConfigMap.set('https://stopclock.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://www.stopclock.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://dls2023strategy.stopclock.co.za', getBrandConfig('dls-za-en'));
brandConfigMap.set('http://localhost:8080', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://localhost:8081', getBrandConfig('dls-za-en'));
brandConfigMap.set('https://poc.stopclock.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://play.stopclock.co.za', getBrandConfig('vc-za-en'));


export function getBrandConfig(brand) {
    const brandConfigObject = brandConfigObjectMap.get(brand);
    return {
        brand,
        productId: brandConfigObject.productId,
        productTitle: brandConfigObject.productTitle,
        categoryId: brandConfigObject.categoryId,
        showTermsConditions: brandConfigObject.showTermsConditions,
        showDataWon: brandConfigObject.showDataWon,
    }
}
