<template>
  <div class="d-flex flex-column text-center align-items-center main-container pt-5" v-if="!isLoading">
    <div class="main-header col-12">
      <div class="col-12">SO CLOSE!</div>
      <img class="col-9 horizontal-line" src="../../assets/horizontal-line.png">
    </div>
    <div class="secondary-text col-11">Unfortunately you did not get more than 7 questions correct.</div>
    <img class="col-4 col-sm-5 col-md-5 col-lg-5 col-xl-5 mt-2" :src="require(`../../assets/score-0${userScore}.png`)">
    <div class="answer-text mt-2">You answered all the questions in:</div>
    <div class="completed-time-text">{{ userQuizFinishingTime }} SECONDS</div>
    <div v-if="dlsUserPlayedToday === undefined" class="play-again-text mt-2">Play again for another chance to WIN!</div>
    <button v-if="dlsUserPlayedToday === undefined" @click="playAgainClicked();" class="play-again-button col-10 mt-1">PLAY AGAIN</button>
    <div @click="leaderboardClicked();" class="leaderboard-text mt-2">View Leaderboard <span>&#8594;</span></div>
    <div class="mt-1">
      <img class="col-5" src="../../assets/stc-quizinc-logo.png">
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "GameFailPage",
  computed: {
    ...mapState(['isLoading', 'userScore', 'userQuizFinishingTime', 'user', 'brandConfig', 'dlsUserPlayedToday'])
  },
  data() {
    return {}
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setDlsUserPlayedToday']),
    playAgainClicked() {
      this.$router.push('/question-screen')
    },
    leaderboardClicked() {
      this.$router.push('/leaderboard')
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: "Komikax";
  src: local("Komikax"),
  url(../../assets/fonts/KOMIKAX_.ttf) format("truetype");
}

.main-container {
  font-family: "Komikax", sans-serif;
  color: #FFFFFF;
}

.main-header, .completed-time-text {
  font-size: 30px;
}

.horizontal-line {
  margin-top: -44px;
}

.secondary-text {
  margin-top: -36px;
}

.answer-text, .secondary-text, .leaderboard-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  font-size: 17px;
}

.completed-time-text {
  margin-top: -10px;
}

.play-again-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  font-size: 18px;
  color: #3cc23c;
}

.play-again-button {
  color: #FFFFFF;
  background-color: #3cc23c;
  font-size: 16px;
  border: 0;
  border-radius: 12px;
  height: 50px;
}


@media screen and (max-width: 400px) {
  .main-header, .completed-time-text {
    font-size: 28px;
  }

  .main-header {
    margin-top: -25px;
  }
}

</style>
