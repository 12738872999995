<template>
  <div class="d-flex flex-column text-center align-items-center main-container">
    <img class="col-5" src="../../../assets/restricted-icon.png">
    <div class="col-9">Content Services are currently blocked for this
      number. If you select ACCEPT you will be allowed to purchase this
      specific content, but other content services will remain blocked.
    </div>
    <button @click="confirmButtonClicked()" class="confirm-button mt-4 col-10">ACCEPT</button>
    <button @click="cancelButtonClicked()" class="cancel-button mt-3 col-10">DECLINE</button>
    <div class="d-flex flex-column align-items-center footer col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2">
      <div @click="termsConditionsClicked()"> T&C's Apply | Subscription Service | R3/day | First Day Free</div>
    </div>
  </div>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import DcbService from "@/services/dcbService";

export default {
  name: "ContentBlock",
  computed: {
    ...mapState(['msisdn', 'encryptedMsisdn', 'token', 'campaignId', 'brandConfig', 'googleClickId'])
  },
  data() {
    return {
      evinaToken: undefined
    }
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    termsConditionsClicked() {
      window.location = 'https://quizinc.co.za/terms_and_conditions';
    },
    confirmButtonClicked() {
      this.$router.push('/opt-in-confirmation');
    },
    async cancelButtonClicked() {
      this.setIsLoading(true);
      await DcbService.subscribeEncryptedUser(this.encryptedMsisdn, this.token, this.campaignId, this.evinaToken, "CBR", this.googleClickId);
      this.setIsLoading(false);
      window.location = 'https://www.vodacom.co.za/vodacom/shopping/v/add-to-bill';
    }
  },
}
</script>

<style scoped>
.main-container {
  color: #FFFFFF;
  padding-top: 40px;
  font-family: "Arial", sans-serif;
}

.footer {
  display: flex;
  text-align: center;
  position: fixed;
  bottom: 5px;
  font-size: 12px;
  color: #808080FF;
}

.confirm-button, .cancel-button {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  border: 0;
  border-radius: 12px;
  height: 65px;
}

.confirm-button {
  background-color: #ff0303;
}

.cancel-button {
  background-color: #808080;
}
</style>
