export const brandConfigObjectMap = new Map();

brandConfigObjectMap.set('vc-za-en', {
    productId: 3,
    productTitle: 'Stop The Clock',
    categoryId: 0,
    showTermsConditions: true,
    showDataWon: true,
});
brandConfigObjectMap.set('dls-za-en', {
    productId: 4,
    productTitle: 'DLS Strategy 2023',
    categoryId: 43,
    showTermsConditions: false,
    showDataWon: false,
});
brandConfigObjectMap.set('poc-za-en', {
    productId: 3,
    productTitle: 'Stop The Clock',
    categoryId: 0,
    showTermsConditions: true,
    showDataWon: true,
});

