<template>
  <div class="d-flex flex-column text-center align-items-center main-container">
    <div class="col-10 header-text mb-3"> WIN <span class="red-text">500MB DATA</span> DAILY IF YOU FINISH IN THE TOP 10 WITH VODACOM'S STOP THE CLOCK!</div>
    <div class="col-10 secondary-text mb-2">An SMS has been sent to your mobile number to authenticate you</div>
    <div class="enter-pin-text mb-2">Enter the PIN below</div>
    <input @keyup.enter="submitOTPClicked()" type="text" placeholder="Enter your OTP" v-model="OTP" class="otp-input col-10">
    <button @click="submitOTPClicked()" class="get-started-button mt-3 col-10">GET STARTED</button>
    <div class="otp-error-container mt-2">
      <div v-if="invalidOtp">Invalid OTP</div>
      <div v-if="otpLimitExceeded">You have exceeded your OTP attempt limit</div>
      <div v-if="otpAlreadyUsed">This OTP has already been used</div>
      <div v-if="otpExpired">This OTP has expired</div>
    </div>
    <div @click="resendOtpClicked()" class="sms-retry-text mt-1">Didn't receive the SMS? <span class="try-again-text mt-1">Try Again</span></div>
    <div v-if="brandConfig.showTermsConditions" class="d-flex flex-column align-items-center footer col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2">
      <img class="col-5 mb-3" src="../../../assets/stc-quizinc-logo.png">
      <div @click="termsConditionsClicked()"> T&C's Apply | Subscription Service | R3/day | First Day Free</div>
    </div>
  </div>
</template>

<script>
import DcbService from "@/services/dcbService";
import TriviaDataService from "@/services/triviaDataService";
import {mapState, mapMutations} from "vuex";

export default {
  name: "SubmitOTP",
  computed: {
    ...mapState(['msisdn', 'user', 'brandConfig', 'token'])
  },
  data() {
    return {
      OTP: undefined,
      hastOtpSent: false,
      otpLimitExceeded: false,
      invalidOtp: false,
      otpAlreadyUsed: false,
      otpExpired: false,
    }
  },
  methods: {
    ...mapMutations(['setUser']),
    async submitOTPClicked() {
      const validateOTPResponse = await DcbService.validateOtp(this.msisdn, this.OTP)
      if (validateOTPResponse.data.sessionId) {
        const sessionId = validateOTPResponse.data.sessionId;
        this.setSessionId(sessionId);
        const userResponse = await DcbService.getUserViaEncryptedMsisdn(this.token, sessionId);
        localStorage.setItem('token', userResponse.data.token);
        this.setUser(userResponse.data);

        if (this.user.status === "Active" || this.brandConfig.brand === 'dls-za-en') {
          await TriviaDataService.upsertUsernameV2(this.encryptedMsisdn, userResponse.data.username);
          this.$router.push('/landing');
        } else if (this.user.status !== "Active") {
          this.$router.push('/opt-in');
        }
      } else {
        if (validateOTPResponse.data.OTPAttemptCount > 2) {
          this.otpLimitExceeded = true;
          this.hastOtpSent = true;
        } else this.invalidOtp = true;
      }
      if (validateOTPResponse.data.UsedOTP) {
        this.hastOtpSent = true;
        this.otpAlreadyUsed = true;
        this.invalidOtp = false;
      }
      if (validateOTPResponse.data.ExpiredOTP) {
        this.hastOtpSent = true;
        this.otpExpired = true;
        this.invalidOtp = false;
      }
    },
    async resendOtpClicked() {
      await DcbService.sendOtp(this.msisdn)
    },
    setSessionId(sessionId) {
      localStorage.setItem('sessionId', sessionId);
    },
    termsConditionsClicked() {
      window.location = 'https://quizinc.co.za/terms_and_conditions';
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: "Komikax";
  src: local("Komikax"),
  url(../../../assets/fonts/KOMIKAX_.ttf) format("truetype");
}

.main-container {
  color: #FFFFFF;
  padding-top: 40px;
}

.header-text {
  font-family: "Komikax", sans-serif;
  font-size: 18px;
}

.red-text {
  color: #ff0303;
}

.secondary-text {
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.enter-pin-text {
  font-family: "Arial", sans-serif;
  font-size: 12px;
  color: #808080FF;
}

.otp-input {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #808080FF;
  border-radius: 12px;
  height: 50px;
  padding-left: 5px;
}

::placeholder {
  font-size: 16px;
  padding-left: 5px;
}

.get-started-button {
  color: #FFFFFF;
  background-color: #ff0303;
  font-size: 18px;
  border: 0;
  border-radius: 12px;
  height: 55px;
}

.footer {
  display: flex;
  text-align: center;
  position: fixed;
  bottom: 5px;
  font-family: "Arial", sans-serif;
  font-size: 12px;
  color: #808080FF;
}

.sms-retry-text, .try-again-text {
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.try-again-text {
  text-decoration: underline;
}

.otp-error-container {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #ff0303;
}
</style>
