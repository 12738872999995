import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";
import store from "@/store";

export default class TriviaDataService {

    static async getTriviaQuestionsV2(encryptedMsisdn, questionCategoryId, startTime) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-questions/v2`, {
            encryptedMsisdn: encryptedMsisdn,
            amountOfQuestions: 1,
            questionCategoryId: questionCategoryId,
            difficulty: 1,
            startTime: startTime,
            productId: store.state.brandConfig.productId,
        })
    }

    static async submitUserAnswer(answer, gameId) {
        return await axios.post(`${environmentConfig.triviaService}/save-user-answers`, {
            answer: answer,
            productId: store.state.brandConfig.productId,
            gameId: gameId,
        })
    }

    static async submitUserAnswerV2(answer, gameId) {
        return await axios.post(`${environmentConfig.triviaService}/save-user-answers/v2`, {
            answer: answer,
            productId: store.state.brandConfig.productId,
            gameId: gameId,
        })
    }

    static async getRoundLeaderboard(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-leaderboard`, {
            msisdn: msisdn,
            productId: store.state.brandConfig.productId,
            numberRows: 10,
        })
    }

    static async getRoundLeaderboardV2(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-leaderboard/v2`, {
            encryptedMsisdn: encryptedMsisdn,
            productId: store.state.brandConfig.productId,
            numberRows: 10,
        })
    }

    static async upsertUsername(msisdn, username) {
        return await axios.post(`${environmentConfig.triviaService}/upsert-username`, {
                msisdn,
                username
            },
        )
    }

    static async upsertUsernameV2(encryptedMsisdn, username) {
        return await axios.post(`${environmentConfig.triviaService}/upsert-username/v2`, {
                encryptedMsisdn,
                username
            },
        )
    }

    static async updateUserLeaderboardDropoutMsgStatus(msisdn, optInStatus) {
        return await axios.post(`${environmentConfig.triviaService}/update-user-leaderboard-msg-status`, {
                msisdn,
                service: "vc-fanclash-quizinc-03",
                optInStatus
            },
        )
    }

    static async updateUserLeaderboardDropoutMsgStatusV2(encryptedMsisdn, optInStatus) {
        return await axios.post(`${environmentConfig.triviaService}/update-user-leaderboard-msg-status/v2`, {
                encryptedMsisdn,
                service: "vc-fanclash-quizinc-03",
                optInStatus
            },
        )
    }
}
